import React from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import NonprofitsPortfolio from '../partials/NonprofitsPortfolio';
import StartupsPortfolio from '../partials/StartupsPortfolio ';

function ForgePortfolio() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <section className="relative">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
              <h1 className="h1">Forge Portfolio</h1>
            </div>
            <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
              <a href="#nonprofitsPortfolio" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                  <span>Nonprofits</span>
              </a>
              <a href="#startupsPortfolio" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                  <span>Startups</span>
              </a>
            </div>
            <hr></hr>
          </div>
        </div>
      </section>
      <NonprofitsPortfolio></NonprofitsPortfolio>
      <hr></hr>
      <StartupsPortfolio></StartupsPortfolio>
      <Footer />
    </div>
  );
}

export default ForgePortfolio;

import React, { useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useFetch } from 'use-http';

function ImageEditor({ src, alt, width, height, awsUploadPath, aspectRatio = 1.0, canvasOptions = {} }) {
  const { post } = useFetch('/api/upload-img', []);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState();
  const [cacheCnt, setCacheCnt] = useState(+new Date());
  const imageRef = useRef(null);
  const uploadCropData = () => {
    if (typeof cropper !== 'undefined') {
      setLoading(true);
      cropper.getCroppedCanvas(canvasOptions).toBlob(async (blob) => {
        const signedUploadUrl = (await post({ path: awsUploadPath })).signedUrl;
        const formData = new FormData();
        formData.append('file', blob);
        await fetch(signedUploadUrl, {
          method: 'PUT',
          body: blob
        });
        setLoading(false);
        setImage(null);
        setCacheCnt(cacheCnt + 1);
      }, 'image/png');
    }
  };
  const id = 'image-upload-' + awsUploadPath.replace('/', '_');
  return (
    <div>
      {!image && !loading && (
        <label for={id}>
          <img className="relative" src={src + '?' + cacheCnt} width={width} height={height} alt={alt} />
          <input
            style={{ display: 'none' }}
            type="file"
            name={id}
            id={id}
            accept="image/*"
            onChange={(e) => {
              setLoading(false);
              const files = e.target.files;
              if (files && files.length > 0) {
                const file = files[0];
                if (URL) {
                  setImage(URL.createObjectURL(file));
                } else if (FileReader) {
                  const reader = new FileReader();
                  reader.onload = () => {
                    setImage(reader.result);
                  };
                  reader.readAsDataURL(file);
                }
              }
            }}
          />
        </label>
      )}
      {image && !loading && (
        <div>
          <Cropper
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={1.0}
            aspectRatio={aspectRatio}
            guides={true}
            src={image}
            ref={imageRef}
            dragMode={'move'}
            checkOrientation={true}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
          <div className="border-t border-gray-200 pt-5 mt-6">
            {/*eslint-disable-next-line*/}
            <a className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full" onClick={() => uploadCropData()}>
              Save Image
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageEditor;

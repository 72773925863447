import React from 'react';

import Header from '../partials/Header';
import Faqs from '../partials/Faqs';
import Footer from '../partials/Footer';

const FAQ_QUESTIONS = [
  {
    title: 'General',
    questions: [
      {
        question: 'What is Texas Convergent?',
        answer: `Texas Convergent is an entrepreneurial, product-building organization at UT Austin. We provide students the necessary skills and resources to drive the future of innovation through a hands-on, project-based curriculum.
        \nConvergent aims to bridge the gap between the Computer Science department and students across the university by providing hands-on experience through collaborative projects and a community for students to launch their ideas. The three cornerstones of Convergent's multidisciplinary methodology are Technology, Business, and Design.`
      },
      {
        question: 'What is the difference between the Build Team and Forge Programs?',
        answer: `Build Team Program - larger, with more guidance, don’t require a lot of tech, business, or design background - develop ideas from scratch to an MVP
          \nForge Programs - smaller, work with established nonprofits or startups, requires some experience with tech, business, or design - work on building out features, apps, UI,  business strategies of current nonprofits or startups
          `
      },
      {
        question: 'What does the time commitment look like?',
        answer: `Build Team
          \n\t- Expected commitment is 1.5 hours a week for weekly meetings.
          \n\t- 1 hour a week for subteam meetings, on your own time
          \n\t- +/- 0.5 hours for working on your own
          \nForge
          \n\t- 1 hour meeting every week
          \n\t- Engineer: ~3-5 hours of coding per week
          \n\t- Designer: ~1-2 hours per week
          \nBut you get out what you put in! If you want to get a really good product at the end of the semester, you’ll most likely spend 3+ hours each week.
          `
      },
      {
        question: 'What does the application process look like?',
        answer: `The Build Team and Forge member application will be released the first week of the Fall Semester.
        \nYou’ll have until September 3rd, 2022 to submit the member application.
        \nYou’ll be notified of the final decision the following week.`
      },
      {
        question: `Can I apply to Build Teams and Forge, or just one or the other?`,
        answer: `You can absolutely apply to both! Applying to be a Build Team member and Forge member will not affect your application to either program.
        \nIf you have a stronger interest in one or the other, please indicate that in your application, in the additional comments section.`
      },
      {
        question: 'Can I apply to Build Teams and Incubator Pods, or just one or the other?',
        answer:
          'You can apply to one or the other. Build Teams are oriented towards students that want a more guided experience through the tech development process, while Incubator pods throw you into the action - working with startups from the get go.'
      },
      {
        question: 'What is the difference between the Build Team and Incubator Programs?',
        answer:
          'Build Teams equip members with the skills, tools, and network necessary to create a “hackathon-esque” product within a semester. Incubator pairs members up with real-life startups and puts them right into the action, allowing them to build technological, business, or design solutions for a specific company.'
      },
      {
        question: 'Will the info sessions be recorded?',
        answer: 'Yes, the virtual aspect of the info sessions will be recorded.'
      },
      {
        question: 'Why is there a fee for joining Convergent?',
        answer:
          'We use the fees to fund social events, t-shirts, and cloud subscriptions (e.g. AWS) throughout the semester. If you have trouble providing dues, please let us know and we will work with you individually!'
      },
      { question: 'What year do you have to be to join?', answer: 'No year requirement, we accept all UT students!' },
      {
        question: 'Any experience needed to join?',
        answer:
          'Nope! However, if you are coming in with minimal experience, we do recommend applying for Build Teams as those are usually less technically intensive and let you ramp up over the semester.'
      },
      {
        question: 'What are the main events Convergent hosts per semester?',
        answer: 'Info Sessions, Super Day, Hack Day, Demo Day, DataHacks, ABSA Startup Simulation'
      }
    ]
  }
];

function FAQ() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <Faqs title="Questions &amp; Answers" style={{ marginTop: '4em' }} faq={FAQ_QUESTIONS} />
      </main>
      <Footer />
    </div>
  );
}

export default FAQ;

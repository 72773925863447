import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import Header from '../partials/Header';
import BuildTeamList from '../partials/BuildTeamList';
import Footer from '../partials/Footer';
import DetailedCTA from './../partials/DetailedCTA';
import BuildTeamFeatures from '../partials/BuildTeamFeatures';
import ProjectsPreview from './../partials/ProjectsPreview';


function BuildTeams({ flags }) {
  const [teams, setTeams] = useState(null);
  const { get } = useFetch(
    `/api/teams-formatted?boardId=${flags?.site_team_boardId}&incubatorId=${flags?.site_team_incubatorId}&btIds=${flags?.site_team_btIds}`,
    []
  );
  useEffect(() => {
    get().then((teams) => setTeams(teams));
  }, [flags, get]);
  const { data: projects = null } = useFetch(`/api/projects-all`, []);
  let btProjectsByYear = {};
  let projectGroups = [];
  if (projects) {
    let btProjects = projects.filter(
      (project) => project.team !== 'Startups' && project.team !== 'Nonprofits' && project.team !== 'Incubator'
    );
    for (let proj of btProjects) {
      if (!proj) {
        continue;
      }
      if (!(proj.year in btProjectsByYear)) {
        btProjectsByYear[proj.year] = [];
      }
      btProjectsByYear[proj.year].push(proj);
    }
    for (let year in btProjectsByYear) {
      projectGroups.push({
        title: '' + year,
        projects: btProjectsByYear[year]
      });
    }
  }

  console.log("teams@@@@: ", teams)
  // getting buildteams
  let buildTeams = [];
  if (teams && teams.buildTeams) {
    for (let bt of teams.buildTeams) {
      buildTeams.push({
        name: bt.name,
        desc: bt.desc,
        leads: bt.leads
      });
    }
  }

  console.log("buildTeams: ", buildTeams);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <BuildTeamFeatures />
      <main className="flex-grow">{teams && teams.buildTeams && <BuildTeamList buildTeams={buildTeams} />}</main>
      {projects && <ProjectsPreview title={'Past Projects'} projects={projectGroups} />}
      <DetailedCTA
        title={`Want to join a build team?`}
        subtitle={'Fill out our quick application.'}
        href={'http://tinyurl.com/f24convergent'}
      />
      <Footer />
    </div>
  );
}

export default BuildTeams;

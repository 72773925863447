import { useState } from 'react';

function AdminTableView({
  title,
  items,
  fields,
  renderPrimaryRow,
  renderPrimaryRowEditor,
  renderCreate,
  searchFilter
}) {
  const [showSearch, setShowSearch] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [query, setQuery] = useState('');
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full border-b border-gray-200">
        <thead>
          <tr className="text-base sm:text-lg border-t border-gray-200">
            <th className="text-bold text-left pr-2 py-4 min-w-48">
              {title} <i onClick={() => setShowEditor(!showEditor)}>[✏️]</i>
              {searchFilter && <i onClick={() => setShowSearch(!showSearch)}>[🔎]</i>}
              {renderCreate && <i onClick={() => setShowCreate(!showCreate)}>[➕]</i>}
              {showSearch && searchFilter && (
                <div className="pt-3">
                  <input
                    className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search..."
                  />
                </div>
              )}
            </th>
            {fields.map((field) => (
              <th style={{ fontWeight: '500', fontSize: '0.9em' }} className="text-medium text-center px-2 py-4">
                {field.name}
              </th>
            ))}
          </tr>
        </thead>
        {items && (
          <tbody>
            {showCreate && (
              <tr className="border-t border-gray-200 mt-3 mb-3" colspan={1 + fields.length}>
                {renderCreate()}
              </tr>
            )}
            {items
              .filter((it) => !searchFilter || !query || searchFilter(query, it))
              .map((item) => (
                <tr className="border-t border-gray-200" key={item.id}>
                  {showEditor && renderPrimaryRowEditor ? (
                    <td className="text-sm sm:text-base pr-2 py-4">{renderPrimaryRowEditor(item)}</td>
                  ) : (
                    <td className="text-sm sm:text-base pr-2 py-4">{renderPrimaryRow(item)}</td>
                  )}
                  {fields.map((field) => (
                    <td className="text-sm px-2 py-4 text-center font-medium">
                      {showEditor && field.renderEditor
                        ? field.renderEditor(item[field.field], item)
                        : field.render(item[field.field], item)}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default AdminTableView;

import React from 'react';
import { Link } from 'react-router-dom';
import { titleCase } from './../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPhotoVideo, faLink, faAward } from '@fortawesome/free-solid-svg-icons';
import { AWS_URL } from './../backend';

function GalleryFooterIcon({ href, icon }) {
  return (
    <a href={href}>
      <FontAwesomeIcon
        icon={icon}
        style={{
          color: 'rgb(0, 112, 244)',
          backgroundColor: '#fff',
          width: '2em',
          height: '2em',
          borderRadius: '1em',
          padding: '0.4em'
        }}
      />
    </a>
  );
}

function ProjectsGallery({ title, projectGroups }) {
  let showProjects = [];
  for (let group of Object.values(projectGroups)) {
    showProjects.push(...group.projects);
  }
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1">{title}</h1>
          </div>
          <div className="border-b border-gray-300 pb-4 mb-12">
            <ul className="flex flex-wrap justify-center md:justify-start font-medium -mx-5 -my-1">
              <li className="mx-5 my-1">
                <a className="text-blue-600" href="#0">
                  All
                </a>
              </li>
            </ul>
          </div>
          <div className="max-w-sm mx-auto md:max-w-none">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
              {showProjects.map((project) => (
                <article className="flex flex-col h-full" data-aos="zoom-y-out">
                  <header>
                    <Link to={project.link} className="block mb-6">
                      <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                        <img
                          className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out"
                          src={`${AWS_URL}/projects/${project.id}.png`}
                          width={352 * 1.75}
                          height={352}
                          alt={project.name}
                        />
                      </figure>
                    </Link>
                    <h3 className="text-xl font-bold leading-snug tracking-tight">
                      <div className="mb-3">
                        <ul className="flex flex-wrap text-xs font-medium -m-1">
                          <li className="m-1">
                            <button className="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out">
                              {titleCase(project.season)} {project.year}
                            </button>
                          </li>
                          {project.team !== 'Incubator' && (
                            <li className="m-1">
                              <button className="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out">
                                {project.team}
                              </button>
                            </li>
                          )}
                          {project.award && (
                            <li className="m-1">
                              <button className="inline-flex text-center text-white py-1 px-3 rounded-full bg-blue-400 hover:bg-blue-600 shadow-sm transition duration-150 ease-in-out">
                                <FontAwesomeIcon
                                  icon={faAward}
                                  style={{
                                    color: '#fff',
                                    width: '1.2em',
                                    height: '1.2em',
                                    paddingRight: '4px'
                                  }}
                                />
                                {project.award}
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                      <a href={project.slidesURL} className="hover:underline">
                        {project.name}
                      </a>
                    </h3>
                  </header>
                  <footer className="text-sm flex items-center mt-4">
                    <div className="text-gray-600">{project.desc}</div>
                  </footer>
                  <div className="pt-2">
                    {project.slidesURL && <GalleryFooterIcon href={project.slidesURL} icon={faCopy} />}
                    {project.videoURL && <GalleryFooterIcon href={project.videoURL} icon={faPhotoVideo} />}
                    {project.websiteURL && <GalleryFooterIcon href={project.websiteURL} icon={faLink} />}
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectsGallery;

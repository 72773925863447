import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AWS_URL } from './../backend';
import { titleCase } from './../utils';

function ForgeList({ forgeTeams }) {
  return (
    <section>
      <div className="pb-12 md:pb-20">
        {forgeTeams.map((team, i) => {
          console.log(team);
          return <ForgeTeam forgeTeam={team} />;
        })}
      </div>
    </section>
  );
}

function ForgeTeam({ forgeTeam }) {
  console.log(`${forgeTeam}`);
  return (
    <article className="py-4 border-b border-gray-300">
      <div>
        <div className="text-lg text-gray-600 mb-4">{forgeTeam.desc}</div>
        <header>
          <h2 className="h4 mb-2">
            {/* <Link to="/blog-post" className="hover:underline">
              {buildTeam.name}
            </Link> */}
            Leads
          </h2>
        </header>
        <footer className="text-sm">
          {forgeTeam.leads.map((lead) => (
            <ForgeLead lead={lead} />
          ))}
        </footer>
      </div>
    </article>
  );
}

function ForgeLead({ lead }) {
  const normalPicSrc = `${AWS_URL}/profiles/${lead.id}-normal.png`;
  const [profileUrl, setProfileUrl] = useState(normalPicSrc);
  return (
    <div className="flex items-center mb-2">
      <div className="flex flex-shrink-0 mr-3">
        <a className="relative" href="#0">
          <span className="absolute inset-0 -m-px" aria-hidden="true">
            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
          </span>
          <img
            className="relative rounded-full"
            src={profileUrl}
            width="32"
            height="32"
            alt={lead.fullname}
            onError={() => setProfileUrl('https://tx-cvgt-public.s3.us-west-2.amazonaws.com/profiles/416-normal.png')}
          />
        </a>
      </div>
      <div>
        <span className="text-gray-600"></span>
        <a className="font-medium hover:underline" href={lead.website}>
          {lead.fullname}
        </a>
        <span className="text-gray-600"> · {titleCase(lead.leadType)} Lead</span>
      </div>
    </div>
  );
}

export default ForgeList;

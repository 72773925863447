import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../partials/Header';
import Footer from '../partials/Footer';

function ToDo() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-3xl mx-auto text-center">
                <h1 className="h1 mb-4">We're still working on this page. 👷</h1>
                <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                  Check back soon!
                </p>
                <div className="mt-8">
                  <Link to="/" className="btn text-white bg-blue-500 hover:bg-blue-700">
                    Go back home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default ToDo;

import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function OrgFeatures({ features, steps }) {
  const [tab, setTab] = useState(0);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs >= 1 && tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px';
    }
  };

  useEffect(() => {
    heightFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <section className="relative" id="about">
      <div className="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          <div className="max-w-3xl mx-auto text-center pb-4 md:pb-4">
            <h1 className="h2 mb-4">How Convergent Works</h1>
            <p className="text-xl text-gray-600">Choose between our build team and forge programs.</p>
          </div>
          <div className="max-w-6xl mx-auto px-4 sm:px-6 pb-8">
            {steps.map((stepGroup) => (
              <div className="py-6 md:py-8">
                <h1 className="h4 mb-1">{stepGroup.title}</h1>
                <p className="text-l text-gray-600 mb-3">{stepGroup.subtitle}</p>
                <div className="relative max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-4 items-start md:max-w-2xl lg:max-w-none">
                  <div
                    className="absolute top-1/2 w-full h-1 bg-gradient-to-r from-blue-500 to-blue-200 hidden lg:block"
                    aria-hidden="true"
                  ></div>
                  {stepGroup.steps.map((step, i) => (
                    <div
                      key={step.title}
                      className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl"
                      style={{ height: '19em' }}
                    >
                      <div className="flex justify-center items-center h-12 w-12 rounded-full bg-blue-500 text-white font-bold mb-3">
                        {i + 1}
                      </div>
                      <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">{step.title}</h4>
                      <p className="text-gray-600 text-center">{step.desc}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="md:grid md:grid-cols-12 md:gap-6">
            <div
              className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6"
              data-aos="fade-right"
            >
              <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                <h3 className="h3 mb-3">What you can do</h3>
                <p className="text-xl text-gray-600">
                  Convergent brings together multidisciplinary talent around UT to build exciting technology that
                  changes lives.
                </p>
              </div>
              <div className="mb-8 md:mb-0">
                {features.map((feat, i) => (
                  <a
                    key={i}
                    className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                      i !== tab
                        ? 'bg-white shadow-md border-gray-200 hover:shadow-lg'
                        : 'bg-gray-200 border-transparent'
                    }`}
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      setTab(i);
                    }}
                  >
                    <div>
                      <div className="font-bold leading-snug tracking-tight mb-1">{feat.title}</div>
                      <div className="text-gray-600">{feat.desc}</div>
                    </div>
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                      <FontAwesomeIcon
                        icon={feat.icon}
                        style={{
                          color: '#555',
                          backgroundColor: '#fff',
                          width: '2em',
                          height: '2em',
                          borderRadius: '1em',
                          padding: '0.4em'
                        }}
                      />
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <div
              className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
              data-aos="zoom-y-out"
              ref={tabs}
            >
              <div className="relative flex flex-col text-center lg:text-right">
                {features.map((feat, i) => (
                  <Transition
                    show={tab === i}
                    appear={true}
                    className="w-full"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterStart="opacity-0 translate-y-16"
                    enterEnd="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveStart="opacity-100 translate-y-0"
                    leaveEnd="opacity-0 -translate-y-16"
                  >
                    <div className="relative inline-flex flex-col">
                      <img
                        className="md:max-w-none mx-auto rounded"
                        src={feat.image}
                        width="500"
                        height="375"
                        alt="Features bg"
                      />
                    </div>
                  </Transition>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OrgFeatures;

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../utils/Transition.js';
import Dropdown from '../utils/Dropdown';
import Logo from '../images/logo.png';

const PAGES = [
  {
    name: 'Build Teams',
    children: [
      { name: 'About', link: '/buildteams' },
      { name: 'Past Projects', link: '/pastprojects' }
    ]
  },
  {
    name: 'Forge',
    children: [
      { name: 'About', link: '/forge' },
      { name: 'Portfolio', link: '/forge-portfolio' }
    ]
  },
  // {
  //   name: 'Incubator',
  //   children: [
  //     { name: 'About', link: '/incubator' },
  //     { name: 'Portfolio', link: '/portfolio' }
  //   ]
  // },
  {
    name: 'People',
    children: [
      { name: 'Officer Team', link: '/team' },
      { name: 'Alumni', link: '/alumni' }
    ]
  },
  {
    name: 'Partnerships',
    link: '/partnerships'
  },
  {
    name: 'FAQ',
    link: '/faq'
  }
];

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [top, setTop] = useState(true);

  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && 'bg-white blur shadow-lg'
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
              <img width={'50px'} src={Logo} alt="logo" />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">
            {/* Desktop menu links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              {PAGES.map((page) => {
                if (page.children) {
                  return (
                    <Dropdown key={page.name} title={page.name}>
                      {page.children.map((childPage) => (
                        <li key={childPage.name}>
                          <Link
                            to={childPage.link}
                            className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                          >
                            {childPage.name}
                          </Link>
                        </li>
                      ))}
                    </Dropdown>
                  );
                } else {
                  return (
                    <li key={page.name}>
                      <Link
                        to={page.link}
                        className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                      >
                        {page.name}
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>

            <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link
                  to="/contact"
                  className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/join" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                  <span>Join</span>
                  <svg
                    className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                      fillRule="nonzero"
                    />
                  </svg>
                </Link>
              </li>
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className="flex md:hidden">
            {/* Hamburger button */}
            <button
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-900"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" />
                <rect y="11" width="24" height="2" />
                <rect y="18" width="24" height="2" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <div ref={mobileNav}>
              <Transition
                show={mobileNavOpen}
                tag="nav"
                id="mobile-nav"
                className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white"
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
              >
                <ul className="px-5 py-2">
                  {PAGES.map((page) => {
                    if (page.children) {
                      return (
                        <li key={page.name} className="py-2 my-2 border-b border-gray-200">
                          <span className="flex text-gray-600 hover:text-gray-900 py-2">{page.name}</span>
                          <ul className="pl-4">
                            {page.children.map((childPage) => (
                              <li>
                                <Link
                                  to={childPage.link}
                                  className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2"
                                >
                                  {childPage.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      );
                    } else {
                      return (
                        <li key={page.title}>
                          <Link to={page.link} className="flex text-gray-600 hover:text-gray-900 py-2">
                            {page.name}
                          </Link>
                        </li>
                      );
                    }
                  })}
                  <li>
                    <Link
                      to="/contact"
                      className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/join" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2">
                      <span>Join</span>
                      <svg
                        className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                          fill="#999"
                          fillRule="nonzero"
                        />
                      </svg>
                    </Link>
                  </li>
                </ul>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

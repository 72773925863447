import React, { useState } from 'react';
import AdminTableView from './AdminTableView';
import useFetch from 'use-http';
import Select from 'react-select';
import AdminSimpleFieldsEditor from './AdminSimpleFieldsEditor';

function AdminTeams() {
  const { data: teams = null } = useFetch('/api/teams?includeUsers=true', []);
  const fields = [
    {
      name: 'Semester',
      field: 'season',
      render: (v, team) => (
        <div>
          {v || '-'}
          <br />
          {team.year || '-'}
        </div>
      ),
      renderEditor: (v, team) => (
        <AdminSimpleFieldsEditor
          fields={[
            { field: 'season', value: v },
            { field: 'year', value: team.year }
          ]}
          restPath={`/api/teams/${team.id}`}
        />
      )
    },
    {
      name: 'Archived',
      field: 'archived',
      render: (v) => (v ? '📜' : 'No'),
      renderEditor: (v, team) => (
        <AdminSimpleFieldsEditor
          fields={[{ field: 'archived', value: v, type: 'checkbox' }]}
          restPath={`/api/teams/${team.id}`}
        />
      )
    },
    {
      name: 'Slack',
      field: 'slackLeadChannelId',
      render: (v, team) => (
        <div>
          Lead Channel {team.slackLeadChannelId || '-'}
          <br />
          Member Channel {team.slackMemberChannelId || '-'}
        </div>
      ),
      renderEditor: (v, team) => (
        <AdminSimpleFieldsEditor
          fields={[
            { field: 'slackLeadChannelId', value: v },
            { field: 'slackMemberChannelId', value: team.slackMemberChannelId }
          ]}
          restPath={`/api/teams/${team.id}`}
        />
      )
    }
  ];
  const seasonMap = { spring: 0, summer: 1, fall: 2 };
  if (teams) {
    teams.sort((a, b) => {
      if (a.year !== b.year) {
        return b.year - a.year;
      }
      return seasonMap[b.season] - seasonMap[a.season];
    });
    for (let team of teams) {
      team.id = team.nameId;
    }
  }
  const { data: users = null } = useFetch('/api/users', []);
  if (users) {
    users.sort((a, b) => a.id - b.id);
  }
  return (
    <AdminTableView
      title={'Teams'}
      fields={fields}
      items={teams}
      searchFilter={(query, team) =>
        String((team.name.toLowerCase() || '') + (team.nameId.toLowerCase() || '')).includes(query.toLowerCase())
      }
      renderPrimaryRow={(team) => (
        <div>
          <div className="font-medium">
            {team.name} (ID: {team.id})
          </div>
          <div className="font-small">{team.nameId}</div>
          <div className="text-gray-600">{team.desc || '-'}</div>
          <div className="mt-2">
            <div className="font-small">
              <b>Team Leaders:</b>{' '}
              {joinOrNone(team.users.filter((u) => u.userTeam.status === 'owner').map((u) => u.fullname))}
            </div>
            <div className="font-small">
              <b>Team Members:</b>{' '}
              {joinOrNone(team.users.filter((u) => u.userTeam.status === 'member').map((u) => u.fullname))}
            </div>
          </div>
        </div>
      )}
      renderPrimaryRowEditor={(team) => <AdminTeamPrimaryEditor team={team} users={users} />}
      renderCreate={() => <AdminTeamCreate />}
    />
  );
}

const joinOrNone = (ary) => {
  return ary.length === 0 ? '-' : ary.join(', ');
};

function AdminTeamCreate() {
  const { post } = useFetch('/api/teams', []);
  const [teamName, setTeamName] = useState('');
  return (
    <div className="ml-5 mr-5 pt-3 pb-3">
      <h5 className="h5">
        <b>team-name</b>
      </h5>
      <p>ex. bt-health-s21, exec-f21. Be sure to copy the pattern from older teams.</p>
      <input
        className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
        value={teamName}
        onChange={(e) => setTeamName(e.target.value)}
        placeholder="team-name"
      />
      {/*eslint-disable-next-line*/}
      <a
        className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full mt-2"
        onClick={() => {
          if (teamName.length === 0) return;
          if (window.confirm(`Create ${teamName}?`)) {
            post({ nameId: teamName, name: teamName.replace('-', ' ') });
            setTeamName('');
          }
        }}
      >
        Create Team
      </a>
    </div>
  );
}

function AdminTeamPrimaryEditor({ team, users }) {
  const { put: putTeam } = useFetch(`/api/teams/${team.nameId}`, []);
  const [nameId, setNameId] = useState(team.nameId);
  const [name, setName] = useState(team.name);
  const [desc, setDesc] = useState(team.desc);
  const [teamUsers, setTeamUsers] = useState(team.users);
  let updateTeamUsers = (newUsers) => {
    const newTeamUsers = teamUsers.filter((u) => !newUsers.find((u2) => u2.id === u.id)).concat(newUsers);
    setTeamUsers(newTeamUsers);
  };
  const editedTeam = { name, nameId, desc, users: teamUsers };
  const edited =
    JSON.stringify(editedTeam) !==
    JSON.stringify({ name: team.name, nameId: team.nameId, desc: team.desc, users: team.users });
  console.log(teamUsers);
  return (
    <div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={nameId}
          onChange={(e) => setNameId(e.target.value)}
        />
      </div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />
      </div>
      {users && (
        <div>
          <Select
            options={users.map((u) => {
              return { value: u.id, label: `${u.fullname} (${u.eid})` };
            })}
            isMulti
            placeholder={'Team Leaders'}
            defaultValue={team.users
              .filter((u) => u.userTeam.status === 'owner')
              .map((u) => ({
                value: u.id,
                label: `${u.fullname} (${u.eid})`
              }))}
            onChange={(e) => {
              updateTeamUsers(
                e.map((val) => {
                  return { ...users.find((u) => u.id === val.value), userTeam: { status: 'owner' } };
                })
              );
            }}
          />
          <Select
            options={users.map((u) => {
              return { value: u.id, label: `${u.fullname} (${u.eid})` };
            })}
            isMulti
            placeholder={'Team Members'}
            defaultValue={team.users
              .filter((u) => u.userTeam.status === 'member')
              .map((u) => ({
                value: u.id,
                label: `${u.fullname} (${u.eid})`
              }))}
            onChange={(e) => {
              updateTeamUsers(
                e.map((val) => {
                  return { ...users.find((u) => u.id === val.value), userTeam: { status: 'member' } };
                })
              );
            }}
          />
        </div>
      )}
      {edited && (
        <div className="border-t border-gray-200 pt-5 mt-6">
          {/*eslint-disable-next-line*/}
          <a
            className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full"
            onClick={() => {
              putTeam(editedTeam);
            }}
          >
            Save
          </a>
        </div>
      )}
    </div>
  );
}

export default AdminTeams;

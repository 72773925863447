import React, { useState } from 'react';

function ForgeHeader() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 md:pt-40">
          <div className="max-w-3xl pb-12 text-center md:text-left">
            <h1 className="h1">Forge</h1>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
              Coding for Good
            </p>
          </div>

          <div className="max-w-3xl text-center md:text-left">
            <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
              Convergent Forge is an on-campus organization that focuses on bridging companies and nonprofits around
              Texas to UT students who are aspiring tech professionals. Students serve as technical leads, product
              leads, design leads, designers, product analysts, and engineers to create impactful solutions and
              experiences through semester-long software projects!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgeHeader;

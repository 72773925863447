import React from 'react';

import Header from '../partials/Header';
import IncubatorModes from '../partials/IncubatorModes';
import useFetch from 'use-http';
import Footer from '../partials/Footer';
import ProjectsPreview from '../partials/ProjectsPreview';
import Steps from '../partials/Steps';

const MODES = [
  {
    title: 'Founder',
    slogan: 'Incubator is helping students launch startups like no other.',
    desc: 'We connect founders with engineers, designers, and business analysts needed to take your venture to the next level. As a part of the program, you will work with a Tech PM and team of engineers to build out an MVP or iterate on an existing product/service. You will also have access to our expert designers who will level up the look and feel of your product and business analysts who will help you streamline the process for raising capital.',
    cta: 'Join us and make your idea a reality.',
    appLink: 'https://www.txconvergent.org/incubatorcompanyapp'
  },
  {
    title: 'Engineer',
    slogan: 'Incubator is an opportunity for you to code for impact!',
    desc: 'As an engineer, you will match with a startup based on your skills and preferences. Throughout the semester, you will work with 2 - 4 other engineers and a Tech PM to execute on deliverables for the founder. This could include building a website/mobile app, refining an AI/ML model, or engineering a biomedical device. By the end of the semester, you will have leveled up your programming skills, connected with aspiring entrepreneurs, and help contribute to launching a startup.',
    cta: 'Join us and use your technical skills to shape the next decade of innovation.',
    appLink: 'https://www.txconvergent.org/incubatorengineerapp'
  },
  {
    title: 'Designer',
    slogan: 'At Incubator, we give you permission to unleash your creativity.',
    desc: 'As a UI/UX designer, you will match with a startup based on your skills and preferences. Throughout the semester, you will work with the Design Director to shape the product design strategy, iterate from low to high fidelity wireframes, and continually improve the user experience.',
    cta: 'Join us and leave your footprint on the next generation of startups.',
    appLink: 'https://www.txconvergent.org/incubatordesignerapp'
  }
];

const STEPS = [
  {
    title: 'Beginning of Each Semester',
    bullets: ['Incubator Company Application', 'Incubator Member Application']
  },
  {
    title: 'Interview',
    bullets: ['Student Startups', 'Engineers and Designers']
  },
  {
    title: 'Startup Pod Matching',
    bullets: ['Founding Team', 'Tech Project Manager', 'UI/UX Designer', 'Buiness Analyst', 'Engineers']
  },
  {
    title: 'During Each Semester',
    bullets: ['Startup Pods work on tech, business, and design deliverable', 'This is when the real magic happens']
  },
  {
    title: 'Demo Day',
    bullets: ['Startup Pods and Build Teams present their work']
  },
  {
    title: 'End of Each Semester',
    bullets: [
      'Texas Convergent Officer Applications',
      'If invited to return, startups decided whether to continue for another semester'
    ]
  }
];

function Incubator() {
  const { data: projects = null } = useFetch(`/api/projects-all`, []);
  let btProjectsByYear = {};
  let projectGroups = [];
  if (projects) {
    let btProjects = projects.filter((project) => project.team === 'Incubator');
    for (let proj of btProjects) {
      if (!proj) {
        continue;
      }
      if (!(proj.year in btProjectsByYear)) {
        btProjectsByYear[proj.year] = [];
      }
      btProjectsByYear[proj.year].push(proj);
    }
    for (let year in btProjectsByYear) {
      projectGroups.push({
        title: '' + year,
        projects: btProjectsByYear[year]
      });
    }
  }
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <IncubatorModes modes={MODES} />
        <Steps
          title={'Program Overview'}
          subtitle={'Have questions? Reach us at incubator@txconvergent.org'}
          steps={STEPS}
        />
        {projects && <ProjectsPreview title={'Past Companies'} overrideLink={'/portfolio'} projects={projectGroups} />}
      </main>
      <Footer />
    </div>
  );
}

export default Incubator;

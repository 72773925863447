import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import JoinPositions from './../partials/JoinPositions';

const POSITIONS = [{ org: 'Convergent', title: 'Member', link: 'http://tinyurl.com/f24convergent' }];

function Join() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <JoinPositions positions={POSITIONS} />
      </main>
      <Footer />
    </div>
  );
}

export default Join;

import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking, faHandshake, faBug } from '@fortawesome/free-solid-svg-icons';

const CONTACT_METHODS = [
  {
    title: 'Prospective Members',
    desc: 'For questions about membership, admissions, and experience.',
    email: 'membership@txconvergent.org',
    icon: faWalking
  },
  {
    title: 'Partnerships',
    desc: 'For questions about sponsoring, events, and packages.',
    email: 'partnerships@txconvergent.org',
    icon: faHandshake
  },
  {
    title: 'Tech Stuff',
    desc: 'For security issues, bugs, and technical support.',
    email: 'eng@txconvergent.org',
    icon: faBug
  }
];

function ContactUs() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-gray-200">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 mb-4" data-aos="zoom-y-out">
                  Contact Us
                </h1>
                <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                  Have a question about Convergent? Here's how you can get in touch with us.
                </p>
              </div>
              <div
                className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none"
                data-aos-id-blocks
              >
                {CONTACT_METHODS.map((contact, i) => (
                  <div
                    className="relative flex flex-col p-6 bg-white rounded shadow-xl"
                    data-aos="zoom-y-out"
                    data-aos-anchor="[data-aos-id-blocks]"
                  >
                    <FontAwesomeIcon
                      icon={contact.icon}
                      style={{
                        color: '#fff',
                        backgroundColor: '#2b9bc0',
                        width: '3em',
                        height: '3em',
                        borderRadius: '1.5em',
                        padding: '0.7em',
                        marginBottom: '10px'
                      }}
                    />
                    <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">{contact.title}</h4>
                    <p className="text-gray-600">{contact.desc}</p>
                    <a href={`mailto:${contact.email}`} className="text-blue-500 pt-3">
                      <i>{contact.email}</i>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default ContactUs;

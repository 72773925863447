import React, { useState } from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import AdminMiscSettings from './AdminMiscSettings';
import AdminTeams from './AdminTeams';
import AdminUsers from './AdminUsers';
import AdminProjects from './AdminProjects';
import AdminAttends from './AdminAttends';

function AdminConfigs() {
  const [tab, setTab] = useState(window.location.hash.replace('#', '') || 'misc');
  const tabs = [
    { name: 'Misc Settings', id: 'misc', component: AdminMiscSettings },
    { name: 'Teams', id: 'teams', component: AdminTeams },
    { name: 'Users', id: 'users', component: AdminUsers },
    { name: 'Projects & Companies', id: 'projects', component: AdminProjects },
    { name: 'Attendance', id: 'attends', component: AdminAttends }
  ];
  const TabComponent = tabs.find((t) => t.id === tab).component;
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Page header */}
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1">Admin</h1>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
              Configure Convergent.
            </p>
          </div>
          <div className="border-b border-gray-300 pb-4 mb-12">
            <ul className="flex flex-wrap justify-center md:justify-start font-medium -mx-5 -my-1">
              {tabs.map((tabItem) => (
                <li className="mx-5 my-1">
                  {/*eslint-disable-next-line*/}
                  <a
                    className={tabItem.id === tab ? 'text-blue-600' : 'text-gray-600'}
                    href={'#' + tabItem.id}
                    onClick={() => {
                      setTab(tabItem.id);
                    }}
                  >
                    {tabItem.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="max-w-sm mx-auto md:max-w-none">
            <TabComponent />
          </div>
        </div>
      </div>
    </section>
  );
}

function AdminDashboard() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <AdminConfigs />
      </main>
      <Footer />
    </div>
  );
}

export default AdminDashboard;

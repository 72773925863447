import React from 'react';

import Header from '../partials/Header';
import PartnershipTiers from '../partials/PartnershipTiers';
import Footer from '../partials/Footer';
import DarkCTA from './../partials/DarkCTA';
import PartnershipsDetails from './../partials/PartnershipsDetails';

const TIERS = [
  {
    name: 'C Tier',
    benefits: [
      { text: 'Listed as an official partner on the Convergent Website ' },
      { text: 'Attendance at Workshops' },
      { text: 'Access to the Convergent Mailing List ' }
    ]
  },
  {
    name: 'B Tier',
    benefits: [
      { text: 'Access to the official Convergent Resume book ' },
      { text: 'Attendance at 1 Special Event (Demo Day or other Competition) ' },
      { text: 'Customized Recruitment Event ' },
      { text: 'Listed as a sponsor on our T-Shirt' }
    ]
  },
  {
    name: 'A Tier',
    benefits: [
      { text: 'Attendance at all Special Events' },
      { text: 'Customized Workshop/Panel' },
      { text: 'Host a Flagship Event' },
      { text: 'Host your own event' }
    ]
  }
];

function Partnershps() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <PartnershipTiers tiers={TIERS} />
        <DarkCTA
          title={'Interested in being a partner?'}
          href={'mailto:partnerships@txconvergent.org'}
          linkText={'Contact Us'}
        />
        <PartnershipsDetails />
      </main>
      <Footer />
    </div>
  );
}

export default Partnershps;

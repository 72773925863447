import React, { useState } from 'react';
import useFetch from 'use-http';
import AdminSimpleFieldsEditor from './AdminSimpleFieldsEditor';
import ImageEditor from '../utils/ImageEditor';
import AdminTableView from './AdminTableView';
import { AWS_URL } from './../backend';

function AdminUserPrimaryEditor({ user }) {
  const { put: putUser } = useFetch(`/api/users/${user.id}`, []);
  const [fullname, setFullname] = useState(user.fullname);
  const [username, setUsername] = useState(user.username);
  const [bio, setBio] = useState(user.bio);
  const editedUser = { fullname, username, bio };
  const edited =
    JSON.stringify(editedUser) !== JSON.stringify({ fullname: user.fullname, username: user.username, bio: user.bio });
  return (
    <div>
      <div className="font-medium">ID: {user.id}</div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
        />
      </div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
      </div>
      {edited && (
        <div className="border-t border-gray-200 pt-5 mt-6">
          {/*eslint-disable-next-line*/}
          <a
            className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full"
            onClick={() => {
              putUser(editedUser);
            }}
          >
            Save Text
          </a>
        </div>
      )}
      <div>
        <ImageEditor
          src={`${AWS_URL}/profiles/${user.id}-normal.png`}
          aspectRatio={1.0}
          awsUploadPath={`profiles/${user.id}-normal.png`}
          width={60}
          height={60}
          canvasOptions={{ width: 500, height: 500, fillColor: '#fff' }}
        />
        <ImageEditor
          src={`${AWS_URL}/profiles/${user.id}-fun.png`}
          aspectRatio={1.0}
          awsUploadPath={`profiles/${user.id}-fun.png`}
          width={60}
          height={60}
          canvasOptions={{ width: 500, height: 500, fillColor: '#fff' }}
        />
      </div>
    </div>
  );
}

function AdminUsers() {
  const { data: users = null } = useFetch('/api/users', []);
  if (users) {
    users.sort((a, b) => a.id - b.id);
  }
  const fields = [
    {
      name: 'Admin',
      field: 'admin',
      render: (v) => (v ? '😎' : 'No'),
      renderEditor: (v, user) => (
        <AdminSimpleFieldsEditor
          fields={[{ field: 'admin', value: v, type: 'checkbox' }]}
          restPath={`/api/users/${user.id}`}
        />
      )
    },
    {
      name: 'Person Info',
      field: 'email',
      render: (v, user) => (
        <div>
          {v || '-'}
          <br />
          {user.eid || '-'}
          <br />
          {user.phoneNum || '-'}
        </div>
      ),
      renderEditor: (v, user) => (
        <AdminSimpleFieldsEditor
          fields={[
            { field: 'email', value: v },
            { field: 'phoneNum', value: user.phoneNum },
            { field: 'eid', value: user.eid }
          ]}
          restPath={`/api/users/${user.id}`}
        />
      )
    },
    {
      name: 'Socials',
      field: 'githubUsername',
      render: (v, user) => (
        <div>
          {v && (
            <a className="text-blue-400" href={'https://github.com/' + v}>
              github
            </a>
          )}
          <br />
          {user.linkedin && (
            <a className="text-blue-400" href={user.linkedin}>
              linkedin
            </a>
          )}
        </div>
      )
    },
    {
      name: 'Title',
      field: 'title',
      render: (v, user) => (
        <div>
          <b>title:</b> {v || '-'}
          <br />
          <b>org title:</b> {user.orgTitle || '-'}
          <br />
          <b>lead type:</b> {user.leadType || '-'}
        </div>
      ),
      renderEditor: (v, user) => (
        <AdminSimpleFieldsEditor
          fields={[
            { field: 'title', value: v },
            { field: 'orgTitle', value: user.orgTitle },
            { field: 'leadType', value: user.leadType }
          ]}
          restPath={`/api/users/${user.id}`}
        />
      )
    },
    {
      name: 'Alumni',
      field: 'isAlumni',
      render: (v, user) => (
        <div>
          {user.displayOnAlumniPage ? 'Displayed' : 'Not Shown'}
          <br />
          {user.isAlumni ? 'Alumni' : 'Not Alumni'}
          <br />({user.fullTimePosition || '-'})
        </div>
      ),
      renderEditor: (v, user) => (
        <AdminSimpleFieldsEditor
          fields={[
            { field: 'displayOnAlumniPage', value: user.displayOnAlumniPage || false, type: 'checkbox' },
            { field: 'isAlumni', value: user.isAlumni || false, type: 'checkbox' },
            { field: 'fullTimePosition', value: user.fullTimePosition || '' }
          ]}
          restPath={`/api/users/${user.id}`}
        />
      )
    },
    {
      name: 'Slack ID',
      field: 'slackId',
      render: (v) => v || '-',
      renderEditor: (v, user) => (
        <AdminSimpleFieldsEditor fields={[{ field: 'slackId', value: v }]} restPath={`/api/users/${user.id}`} />
      )
    }
  ];
  return (
    <AdminTableView
      title={'Users'}
      fields={fields}
      items={users}
      searchFilter={(query, user) => String(user.eid + user.fullname?.toLowerCase()).includes(query.toLowerCase())}
      renderPrimaryRow={(user) => (
        <div>
          <div className="font-medium">
            {user.fullname} (ID: {user.id})
          </div>
          <div className="font-small">{user.username}</div>
          <div className="text-gray-600">{user.bio || '-'}</div>
          <div>
            <img
              className="relative"
              src={`${AWS_URL}/profiles/${user.id}-normal.png`}
              width="60"
              height="60"
              alt={user.fullname}
            />
            <img
              className="relative"
              src={`${AWS_URL}/profiles/${user.id}-fun.png`}
              width="60"
              height="60"
              alt={user.fullname}
            />
          </div>
        </div>
      )}
      renderPrimaryRowEditor={(user) => <AdminUserPrimaryEditor user={user} />}
    />
  );
}

export default AdminUsers;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AWS_URL } from './../backend';
import { titleCase } from './../utils';

function BuildTeamList({ buildTeams }) {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <h3 className="text-center h3 mb-4">Teams</h3>
          <div className="md:flex md:justify-between">
            <div className="md:flex-grow -mt-4">
              {buildTeams.map((team, i) => {
                console.log(team);
                return <BuildTeam buildTeam={team} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function BuildTeam({ buildTeam }) {
  console.log(`BuildTeam: ${buildTeam}`);
  return (
    <article className="flex items-center py-4 border-b border-gray-200">
      <div>
        <header>
          <h2 className="h4 mb-2">
            {/* <Link to="/blog-post" className="hover:underline">
              {buildTeam.name}
            </Link> */}
            {buildTeam.name}
          </h2>
        </header>
        <div className="text-lg text-gray-600 mb-4">{buildTeam.desc}</div>
        <footer className="text-sm">
          {buildTeam.leads.map((lead) => (
            <BuildTeamLead lead={lead} />
          ))}
        </footer>
      </div>
    </article>
  );
}

function BuildTeamLead({ lead }) {
  const normalPicSrc = `${AWS_URL}/profiles/${lead.id}-normal.png`;
  const [profileUrl, setProfileUrl] = useState(normalPicSrc);
  return (
    <div className="flex items-center">
      <div className="flex flex-shrink-0 mr-3">
        <a className="relative" href="#0">
          <span className="absolute inset-0 -m-px" aria-hidden="true">
            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
          </span>
          <img
            className="relative rounded-full"
            src={profileUrl}
            width="32"
            height="32"
            alt={lead.fullname}
            onError={() => setProfileUrl('https://tx-cvgt-public.s3.us-west-2.amazonaws.com/profiles/416-normal.png')}
          />
        </a>
      </div>
      <div>
        <span className="text-gray-600"></span>
        <a className="font-medium hover:underline" href={lead.website}>
          {lead.fullname}
        </a>
        <span className="text-gray-600"> · {titleCase(lead.leadType)} Lead</span>
      </div>
    </div>
  );
}

export default BuildTeamList;

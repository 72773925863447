import React from 'react';

function JoinPositions({ positions }) {
  return (
    <section className="bg-gradient-to-b from-white to-gray-100">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h1 className="h1 mb-4" data-aos="zoom-y-out">
              Join Convergent
            </h1>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
              Build something cool, learn useful skills, and meet interesting people.
            </p>
          </div>

          <div className="max-w-3xl mx-auto">
            <div className="pb-12 md:pb-20">
              <div className="relative w-full h-0 pb-3/4">
                <figure
                  className="absolute h-auto"
                  style={{ bottom: '9%', width: '41.7%', maxWidth: '320px' }}
                  data-aos="fade-right"
                >
                  <img
                    className="shadow-2xl"
                    src={require('../images/team-mosaic-03.jpg').default}
                    width="320"
                    height="240"
                    alt="Team mosaic 03"
                  />
                </figure>
                <figure
                  className="absolute"
                  style={{ left: '14.8%', top: '11.8%', width: '70.3%', maxWidth: '540px' }}
                  data-aos="fade-down"
                  data-aos-delay="150"
                >
                  <img
                    className="shadow-2xl"
                    src={require('../images/team-mosaic-01.jpg').default}
                    width="540"
                    height="360"
                    alt="Team mosaic 01"
                  />
                </figure>
                <figure
                  className="absolute h-auto"
                  style={{ bottom: '0', right: '6.2%', width: '41.7%', maxWidth: '320px' }}
                  data-aos="fade-left"
                  data-aos-delay="300"
                >
                  <img
                    className="shadow-2xl"
                    src={require('../images/team-mosaic-02.jpg').default}
                    width="320"
                    height="240"
                    alt="Team mosaic 02"
                  />
                </figure>
                <figure
                  className="absolute h-auto"
                  style={{ top: '5.9%', right: '0', width: '23.4%', maxWidth: '180px' }}
                  data-aos="zoom-y-out"
                  data-aos-delay="450"
                >
                  <img
                    className="shadow-2xl"
                    src={require('../images/team-mosaic-04.jpg').default}
                    width="180"
                    height="180"
                    alt="Team mosaic 04"
                  />
                </figure>
              </div>
            </div>
          </div>

          <div className="max-w-3xl mx-auto">
            <h3 className="h4 mb-8">Current Applications</h3>
            <div className="-my-3">
              {positions.map((pos, i) => (
                <a
                  className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-gray-100 shadow-md hover:bg-white hover:shadow-lg mb-3"
                  href={pos.link}
                >
                  <div className="font-medium">
                    <b>{pos.org}</b> {pos.title}
                  </div>
                  <svg
                    className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.707 1h-8v2h5.586L1 13.293l1.414 1.414L12.707 4.414V10h2V2a1 1 0 00-1-1z" />
                  </svg>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoinPositions;

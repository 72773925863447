import React from 'react';

function Steps({ title, subtitle, steps }) {
  let rows = [];
  let numSteps = steps.length
  for (let stepIdx in steps) {
    let row = Math.floor(stepIdx / 4);
    let col = stepIdx % 4;
    if (col === 0) {
      rows.push([]);
    }
    let newStep = JSON.parse(JSON.stringify(steps[stepIdx]));
    newStep.number = '' + (parseInt(stepIdx) + 1);
    rows[row].push(newStep);
  }

  let timelineConfig = "relative max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-4 items-start md:max-w-2xl lg:max-w-none";
  if (numSteps <= 3){
    timelineConfig = "relative max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-" + numSteps + " items-start md:max-w-2xl lg:max-w-none"
  }
  
  return (
    <section className="relative" id="about">
      <div className="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          <div className="max-w-3xl mx-auto text-center pb-3 md:pb-4">
            <h1 className="h2 mb-4">{title}</h1>
            <p className="text-xl text-gray-600">{subtitle}</p>
          </div>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            {rows.map((row) => (
              <div className="py-12 md:py-16">
                <div className={timelineConfig}>
                  <div
                    className="absolute top-1/2 w-full h-1 bg-gradient-to-r from-blue-500 to-blue-200 hidden lg:block"
                    aria-hidden="true"
                  ></div>
                  {row.map((step) => {
                    if (step.desc) {
                      return (
                        <div
                          key={step.title}
                          className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl"
                        >
                          <div className="flex justify-center items-center h-12 w-12 rounded-full bg-blue-500 text-white font-bold mb-3">
                            {step.number}
                          </div>
                          <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">{step.title}</h4>
                          <p className="text-gray-600 text-center">{step.desc}</p>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={step.title}
                          className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl"
                        >
                          <div className="flex justify-center items-center h-12 w-12 rounded-full bg-blue-500 text-white font-bold mb-3">
                            {step.number}
                          </div>
                          <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">{step.title}</h4>
                          <div style={{ width: '100%' }}>
                            <ul className="text-gray-600 pt-2 pl-4" style={{ listStyleType: 'disc' }}>
                              {step.bullets.map((bul) => (
                                <li key={bul}>{bul}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Steps;

import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import ForgeNonProfits from '../partials/ForgeNonProfits';
import ForgeStartups from '../partials/ForgeStartups';
import ForgeAll from '../partials/ForgeAll';
import ForgeHeader from '../partials/ForgeHeader';
import ForgeList from '../partials/ForgeList';

// let forgeTeams = [
//   {
//     leads: [
//       { id: 709, fullname: 'Alisha Murty', team: 'Forge', leadType: 'Tech', website: null },
//       { id: 719, fullname: 'Naman Arora', team: 'Forge', leadType: 'Tech', website: null },
//       { id: 359, fullname: 'Jonathan Tung', team: 'Forge', leadType: 'Tech', website: null },
//       { id: 678, fullname: 'Saiprathik Chalamkuri', team: 'Forge', leadType: 'Tech', website: null },
//       { id: 0, fullname: 'Sana Kohli', team: 'Forge', leadType: 'Tech', website: null },
//       { id: 0, fullname: 'Matt Tolea', team: 'Forge', leadType: 'Tech', website: null },
//       { id: 0, fullname: 'Abhinav Kolli', team: 'Forge', leadType: 'Tech', website: null },
//       { id: 0, fullname: 'Alhassan Lamin', team: 'Forge', leadType: 'Tech', website: null },
//       { id: 0, fullname: 'Dharin Chandran', team: 'Forge', leadType: 'Tech', website: null },
//       { id: 625, fullname: 'Siddhi Bansal', team: 'Forge', leadType: 'Product', website: null },
//       { id: 465, fullname: 'Dina Hormuzdi', team: 'Forge', leadType: 'Product', website: null },
//       { id: 0, fullname: 'Abhinav Varre', team: 'Forge', leadType: 'Product', website: null },
//       { id: 466, fullname: 'Audrey Ly', team: 'Forge', leadType: 'Product', website: null },
//       { id: 721, fullname: 'Imelda Ishiekwene', team: 'Forge', leadType: 'Design', website: null },
//       { id: 0, fullname: 'Camille	Chuduc', team: 'Forge', leadType: 'Design', website: null },
//       { id: 0, fullname: 'Aparna Shah', team: 'Forge', leadType: 'Design', website: null },
//       { id: 0, fullname: 'Nhi Pham', team: 'Forge', leadType: 'Design', website: null },
//       { id: 720, fullname: 'Ellis Brown', team: 'Forge', leadType: 'Design', website: null }
//     ]
//   }
// ];

function Forge({ flags }) {
  const [teams, setTeams] = useState(null);
  const { get } = useFetch(
    `/api/teams-formatted?boardId=${flags?.site_team_boardId}&incubatorId=${flags?.site_team_incubatorId}&btIds=${flags?.site_team_btIds}`,
    []
  );
  useEffect(() => {
    get().then((teams) => setTeams(teams));
  }, [flags, get]);

  // getting Forge Teams
  let forgeTeams = [];
  if (teams && teams.incubLeads) {
    forgeTeams.push({
      leads:teams.incubLeads
    })

  }


  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <ForgeHeader></ForgeHeader>
      {teams && teams.buildTeams && (
        <>
          {/* DEPRECIATED: Forge is now one thing. */}
          {/* <ForgeNonProfits forgeTeams={forgeTeams}></ForgeNonProfits>
          <ForgeStartups forgeTeams={forgeTeams}></ForgeStartups> */}
          <ForgeAll forgeTeams = {forgeTeams}></ForgeAll>
        </>
      )}
      <Footer />
    </div>
  );
}

export default Forge;

import React, { useState } from 'react';

const buttonStyle = {
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  width: 'auto',
  color: '#191919',
  backgroundColor: '#ffffff2b',
  fontWeight: 'bold',
  fontSize: '36px',
  padding: '16px',
  userSelect: 'none',
  transition: '0.6s ease',
  marginTop: '-22px'
};

function SlideShow({ images }) {
  let [slide, setSlide] = useState(0);
  return (
    <div>
      <div className="relative flex justify-center mb-8" data-aos="zoom-y-out" data-aos-delay="450">
        <div className="flex flex-col justify-center" style={{ display: 'grid' }}>
          {images.map((img, i) => (
            <div style={{ opacity: i === slide ? '100' : '0', transition: 'opacity 1s', gridColumn: 1, gridRow: 1 }}>
              <img className="mx-auto" src={img.url} width="768" height="432" alt={img.caption} />
            </div>
          ))}
          <div>
            {/* eslint-disable-next-line */}
            <a
              style={{ ...buttonStyle, borderRadius: '0 10px 10px 0', left: '0' }}
              onClick={() => setSlide((slide - 1 + images.length) % images.length)}
            >
              &#10094;
            </a>
            {/* eslint-disable-next-line */}
            <a
              style={{ ...buttonStyle, borderRadius: '10px 0 0 10px', right: '0' }}
              onClick={() => setSlide((slide + 1) % images.length)}
            >
              &#10095;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlideShow;

import React from 'react';
import { AWS_URL } from './../backend';

function PartnershipTiers({ tiers }) {
  return (
    <section className="bg-gradient-to-b from-white to-gray-100">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h1 className="h1 mb-4" data-aos="zoom-y-out">
              Partnerships
            </h1>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
              Partner to build the future of technology &amp; business
            </p>
          </div>
          <div>
            <h4 className="h4 mb-4 text-center">Partnership Tiers</h4>
            <div
              className={`pb-12 max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-8 md:grid-cols-${tiers.length} xl:grid-cols-${tiers.length} xl:gap-6 items-start`}
            >
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl"
                  data-aos="zoom-y-out"
                  data-aos-delay="450"
                >
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-1">{tier.name}</div>
                  </div>
                  <ul className="text-gray-600 -mb-2 flex-grow">
                    {tier.benefits.map((benefit) => (
                      <li className="flex items-center mb-2">
                        <svg
                          className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>{benefit.text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <h4 className="h4 mb-4 text-center">Current Partners</h4>
            <div className="flex items-center justify-center">
              <img src={AWS_URL + '/images/sponsor-logos.png'} alt="logos" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnershipTiers;

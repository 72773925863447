import React from 'react';

function BuildTeamFeatures() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1 mb-4">Build Teams</h1>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
              Developing and prototyping ideas
            </p>
          </div>
        </div>
        <h1 className="h3 mb-4">How Build Teams Work</h1>
        <p className="text-xl text-gray-600">
          Build Teams are agile, multidisciplinary teams of 5-10 students that turn ideas into minimum viable products.
          Over the course of a semester, Build Team members learn to build and design tech prototypes, business
          proposals, and pitches through a hands-on, case-based curriculum. We welcome students from all majors and
          experience levels!
        </p>
      </div>
    </section>
  );
}

export default BuildTeamFeatures;

import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import '../css/additional-styles/insta-feed.css';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../images/logo.png';

const MAX_LENGTH = 200;
const InstaFeed = () => {
  const [posts, setPosts] = useState([]);
  const truncateString = (str) => {
    if (str.length <= MAX_LENGTH) return str;
    return str.slice(0, MAX_LENGTH) + '...';
  };

  const {
    data = [],
    loading,
    error
  } = useFetch(
    `https://graph.instagram.com/me/media?fields=id,caption,media_url,thumbnail_url,permalink,media_type&access_token=IGQWRNYnpqTTZA5Q2h6U3JuUFFMcVJqaFFRT2RzdEpwVU1WbDNmM01sSGtjdTlJREtNeTVJLVJlVU9BTHpUbTViSzNhVktPYkMxYjlvSnVTOGhIZAkRFanZAVcklnb2hBdWE3R29JSGZAURjlmdwZDZD`,
    {},
    []
  );

  // Render the Instagram feed (step 5)
  const renderPosts = () => {
    if (!data.data) {
      return <div></div>;
    }
    return data.data.map((post) => {
      return (
        <div key={post.id} className="instagram-post">
          {post.media_type === 'IMAGE' || post.media_type === 'CAROUSEL_ALBUM' ? (
            <img src={post.media_url} alt={post.caption} className="post-image" />
          ) : (
            <video src={post.media_url} controls className="post-video" />
          )}
          <p className="post-caption">{truncateString(post.caption)}</p>
          <a
            href={`https://www.instagram.com/txconvergent/`}
            target="_blank"
            rel="noopener noreferrer"
            className="post-link"
          >
            <div className="profile-info">
              <img src={Logo} alt={`${post.username} profile picture`} className="profile-image" />
              <p className="profile-name">txconvergent {'(@txconvergent)'}</p>
              <FontAwesomeIcon
                icon={faInstagram}
                style={{
                  color: '#555',
                  backgroundColor: '#fff',
                  width: '2em',
                  height: '2em',
                  borderRadius: '1em',
                  padding: '0.4em'
                }}
              />
            </div>
          </a>
        </div>
      );
    });
  };

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="pb-12 md:pb-12">
        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-12">
          <h2 className="h2">Recent Photos</h2>
        </div>
        <div className="instagram-feed">{!loading ? renderPosts() : <p>Loading Instagram feed...</p>}</div>
      </div>
    </div>
  );
};

export default InstaFeed;

import React, { useState } from 'react';
import useFetch from 'use-http';
import AdminSimpleFieldsEditor from './AdminSimpleFieldsEditor';
import ImageEditor from '../utils/ImageEditor';
import AdminTableView from './AdminTableView';
import { AWS_URL } from './../backend';

function AdminProjects() {
  const { data: projects = null } = useFetch('/api/projects-all', []);
  if (projects) {
    projects.sort((a, b) => b.id - a.id);
  }
  const fields = [
    {
      name: 'Semester',
      field: 'season',
      render: (v, team) => (
        <div>
          {v || '-'}
          <br />
          {team.year || '-'}
        </div>
      ),
      renderEditor: (v, team) => (
        <AdminSimpleFieldsEditor
          fields={[
            { field: 'season', value: v },
            { field: 'year', value: team.year }
          ]}
          restPath={`/api/projects/${team.id}`}
        />
      )
    },
    {
      name: 'Slides/Video/Site',
      field: 'slidesURL',
      render: (v, project) => (
        <>
          {v ? (
            <a className="text-blue-400" href={v}>
              slides
            </a>
          ) : (
            '-'
          )}
          <br />
          {project.videoURL ? (
            <a className="text-blue-400" href={project.videoURL}>
              video
            </a>
          ) : (
            '-'
          )}
          <br />
          {project.websiteURL ? (
            <a className="text-blue-400" href={project.websiteURL}>
              website
            </a>
          ) : (
            '-'
          )}
        </>
      ),
      renderEditor: (v, team) => (
        <AdminSimpleFieldsEditor
          fields={[
            { field: 'slidesURL', value: v },
            { field: 'videoURL', value: team.videoURL },
            { field: 'websiteURL', value: team.websiteURL }
          ]}
          restPath={`/api/projects/${team.id}`}
        />
      )
    },
    {
      name: 'Awards',
      field: 'award',
      render: (v) => v || '-',
      renderEditor: (v, project) => (
        <AdminSimpleFieldsEditor
          fields={[{ field: 'award', value: v || '' }]}
          restPath={`/api/projects/${project.id}`}
        />
      )
    }
  ];
  const seasonMap = { spring: 0, summer: 1, fall: 2 };
  if (projects) {
    projects.sort((a, b) => {
      if (a.year !== b.year) {
        return b.year - a.year;
      }
      return seasonMap[b.season] - seasonMap[a.season];
    });
  }
  return (
    <AdminTableView
      title={'Projects'}
      fields={fields}
      items={projects}
      searchFilter={(query, project) => String(project.name.toLowerCase()).includes(query.toLowerCase())}
      renderPrimaryRow={(project) => (
        <div>
          <div className="font-medium">
            {project.name} (ID: {project.id})
          </div>
          <div className="font-small">{project.team}</div>
          <div className="text-gray-600">{project.desc || '-'}</div>
          <img
            className="relative"
            src={`${AWS_URL}/projects/${project.id}.png`}
            width="105"
            height="60"
            alt={project.name}
          />
        </div>
      )}
      renderPrimaryRowEditor={(project) => <AdminProjectPrimaryEditor project={project} />}
      renderCreate={() => <AdminProjectsCreate />}
    />
  );
}

function AdminProjectPrimaryEditor({ project }) {
  const { put: putProject } = useFetch(`/api/projects/${project.id}`, []);
  const [name, setName] = useState(project.name);
  const [team, setTeam] = useState(project.team);
  const [desc, setDesc] = useState(project.desc);
  const editedProject = { name, team, desc };
  const edited =
    JSON.stringify(editedProject) !== JSON.stringify({ name: project.name, team: project.team, desc: project.desc });
  return (
    <div>
      <div className="font-medium">ID: {project.id}</div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={team}
          onChange={(e) => setTeam(e.target.value)}
        />
      </div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />
      </div>
      {edited && (
        <div className="border-t border-gray-200 pt-5 mt-6">
          {/*eslint-disable-next-line*/}
          <a
            className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full"
            onClick={() => {
              putProject(editedProject);
            }}
          >
            Save Text
          </a>
        </div>
      )}
      <div>
        <ImageEditor
          src={`${AWS_URL}/projects/${project.id}.png`}
          aspectRatio={1.75}
          awsUploadPath={`projects/${project.id}.png`}
          width={105}
          height={60}
          canvasOptions={{ width: 250, height: 352, fillColor: '#fff' }}
        />
      </div>
    </div>
  );
}

function AdminProjectsCreate() {
  const { post } = useFetch('/api/projects', []);
  const [projectName, setProjectName] = useState('');
  return (
    <div className="ml-5 mr-5 pt-3 pb-3">
      <h5 className="h5">
        <b>Project Name</b>
      </h5>
      <p>Be sure to copy the pattern from older teams. You can edit the other fields after creating.</p>
      <input
        className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
        value={projectName}
        onChange={(e) => setProjectName(e.target.value)}
        placeholder="Project Name"
      />
      {/*eslint-disable-next-line*/}
      <a
        className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full mt-2"
        onClick={() => {
          if (projectName.length === 0) return;
          if (window.confirm(`Create ${projectName}?`)) {
            post({ name: projectName });
            setProjectName('');
          }
        }}
      >
        Create Project
      </a>
    </div>
  );
}

export default AdminProjects;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import useFetch from 'use-http';

function ConfigAdmins() {
  const { data: admins = null } = useFetch('/api/users/admins', []);
  const { data: users = null } = useFetch('/api/users', []);
  const { put: putUser, loading } = useFetch('/api/users');
  useEffect(() => {
    if (admins && admins.error) {
      window.location.href = '/admin';
    }
  }, [admins]);
  return (
    <article className="flex flex-col h-full border-2 p-2" data-aos="zoom-y-out">
      <header>
        <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">Admins</h3>
      </header>
      <p className="text-gray-600 flex-grow mb-3">Set who is an admin.</p>
      {!loading && admins && users && (
        <div>
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
            {admins.map((user) => (
              <li>
                <i
                  onClick={() => {
                    if (window.confirm(`Remove admin ${user.fullname}?`)) {
                      putUser('/' + user.id, { admin: false });
                    }
                  }}
                >
                  [❌]
                </i>{' '}
                {user.fullname} (ID: {user.id})
              </li>
            ))}
          </ul>
          <div className="pt-3">
            <Select
              options={users.map((u) => {
                return { value: u.id, label: u.fullname };
              })}
              placeholder={'Add admin'}
              onChange={(e) => {
                if (window.confirm(`Admin ${e.label}?`)) {
                  putUser('/' + e.value, { admin: true });
                }
              }}
            />
          </div>
        </div>
      )}
    </article>
  );
}

function ConfigRedirects() {
  const { data: flags = null, put: putFlags, loading } = useFetch('/api/flags', []);
  const [editedFlags, setEditedFlags] = useState({});
  useEffect(() => {
    setEditedFlags(flags);
  }, [flags]);
  let redirectFlags = [];
  if (flags) {
    for (let flagName in flags) {
      if (!flagName.includes('_redirect_')) {
        continue;
      }
      redirectFlags.push({ name: flagName, value: flags[flagName] });
    }
  }
  let edited = JSON.stringify(editedFlags) !== JSON.stringify(flags);
  return (
    <article className="flex flex-col h-full border-2 p-2" data-aos="zoom-y-out">
      <header>
        <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">Redirects</h3>
      </header>
      <p className="text-gray-600 flex-grow mb-3">Set website redirects.</p>
      {editedFlags && !loading && (
        <div>
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
            {redirectFlags.map((flag) => (
              <li>
                {flag.name.replace('site_redirect_', '/')}
                <div className="relative flex items-center max-w-xs mb-3">
                  <input
                    className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
                    value={editedFlags[flag.name]}
                    onChange={(e) => setEditedFlags({ ...editedFlags, [flag.name]: e.target.value })}
                    required
                  />
                </div>
              </li>
            ))}
          </ul>
          {edited ? (
            <div className="border-t border-gray-200 pt-5 mt-6">
              {/*eslint-disable-next-line*/}
              <a
                className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full"
                onClick={() => {
                  if (window.confirm('Update redirects?')) {
                    putFlags(editedFlags);
                  }
                }}
              >
                Save changes
              </a>
            </div>
          ) : (
            <div className="border-t border-gray-200 pt-5 mt-6">
              {/*eslint-disable-next-line*/}
              <a className="btn-sm text-white bg-gray-600 w-full">No changes</a>
            </div>
          )}
        </div>
      )}
    </article>
  );
}

function ConfigPrimaryTeams() {
  const { data: flags = null, put: putFlags, loading } = useFetch('/api/flags', []);
  const { data: teams = null } = useFetch('/api/teams-formatted', []);
  const [editedFlags, setEditedFlags] = useState({});
  useEffect(() => {
    setEditedFlags(flags);
  }, [flags]);
  let edited = JSON.stringify(editedFlags) !== JSON.stringify(flags);
  return (
    <article className="flex flex-col h-full border-2 p-2" data-aos="zoom-y-out">
      <header>
        <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">Primary Teams</h3>
      </header>
      <p className="text-gray-600 flex-grow mb-3">Set current teams.</p>
      {editedFlags && teams && !loading && (
        <div>
          <div>
            <Select
              options={teams.teams.map((t) => {
                return { value: t.nameId, label: t.name };
              })}
              placeholder={'Current Board'}
              defaultValue={{
                value: flags.site_team_boardId,
                label: teams.teams.find((t) => t.nameId === flags.site_team_boardId)?.name
              }}
              onChange={(e) => {
                setEditedFlags({ ...editedFlags, site_team_boardId: e.value });
              }}
            />
            <br />
            <Select
              options={teams.teams.map((t) => {
                return { value: t.nameId, label: t.name };
              })}
              placeholder={'Current Incubator'}
              defaultValue={{
                value: flags.site_team_incubatorId,
                label: teams.teams.find((t) => t.nameId === flags.site_team_incubatorId)?.name
              }}
              onChange={(e) => {
                setEditedFlags({ ...editedFlags, site_team_incubatorId: e.value });
              }}
            />
            <br />
            <Select
              options={teams.teams.map((t) => {
                return { value: t.nameId, label: t.name };
              })}
              isMulti
              placeholder={'Current Build Teams'}
              defaultValue={flags.site_team_btIds.split(',').map((btNameId) => ({
                value: btNameId,
                label: teams.teams.find((t) => t.nameId === btNameId)?.name
              }))}
              onChange={(e) => {
                setEditedFlags({ ...editedFlags, site_team_btIds: e.map((v) => v.value).join(',') });
              }}
            />
          </div>
          {edited ? (
            <div className="border-t border-gray-200 pt-5 mt-6">
              {/*eslint-disable-next-line*/}
              <a
                className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full"
                onClick={() => {
                  if (window.confirm('Update current teams?')) {
                    putFlags(editedFlags);
                  }
                }}
              >
                Save changes
              </a>
            </div>
          ) : (
            <div className="border-t border-gray-200 pt-5 mt-6">
              {/*eslint-disable-next-line*/}
              <a className="btn-sm text-white bg-gray-600 w-full">No changes</a>
            </div>
          )}
        </div>
      )}
    </article>
  );
}

function ConfigShop() {
  const { post: refresh } = useFetch('/api/shop/refresh', []);
  return (
    <article className="flex flex-col h-full border-2 p-2" data-aos="zoom-y-out">
      <header>
        <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">Shop</h3>
      </header>
      <div>
        <button
          className="btn-sm text-white bg-blue-600 w-full"
          onClick={async () => {
            await refresh();
            alert('Shop refreshed!');
          }}
        >
          Refresh Products
        </button>
      </div>
    </article>
  );
}

function AdminMiscSettings() {
  return (
    <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
      <ConfigAdmins />
      <ConfigRedirects />
      <ConfigPrimaryTeams />
      <ConfigShop />
    </div>
  );
}

export default AdminMiscSettings;

import React, { useState } from 'react';
import useFetch from 'use-http';
import AdminTableView from './AdminTableView';
import AdminSimpleFieldsEditor from './AdminSimpleFieldsEditor';

function AdminAttends() {
  const { data: attends = null } = useFetch('/api/attends', []);
  const fields = [
    {
      name: 'Archived',
      field: 'archived',
      render: (v) => (v ? '📜' : 'No'),
      renderEditor: (v, team) => (
        <AdminSimpleFieldsEditor
          fields={[{ field: 'archived', value: v, type: 'checkbox' }]}
          restPath={`/api/attends/${team.id}`}
        />
      )
    }
  ];
  if (attends) {
    attends.sort((a, b) => b.id - a.id);
  }
  return (
    <AdminTableView
      title={'Attendance Events'}
      fields={fields}
      items={attends}
      searchFilter={(query, attend) => String(attend.name.toLowerCase()).includes(query.toLowerCase())}
      renderPrimaryRow={(attend) => (
        <div>
          <div className="font-medium">
            {attend.name} (ID: {attend.id})
          </div>
          <div className="text-gray-600">{attend.desc || '-'}</div>
        </div>
      )}
      renderCreate={() => <AdminAttendCreate />}
      renderPrimaryRowEditor={(attend) => <AdminAttendPrimaryEditor attend={attend} />}
    />
  );
}

function AdminAttendCreate() {
  const { post } = useFetch('/api/attends', []);
  const [attendName, setAttendName] = useState('');
  return (
    <div className="ml-5 mr-5 pt-3 pb-3">
      <h5 className="h5">
        <b>Build Team Meeting [Semester][Year] [Meeting Number]</b>
      </h5>
      <p>ex. Build Team Meeting F21 01</p>
      <input
        className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
        value={attendName}
        onChange={(e) => setAttendName(e.target.value)}
        placeholder="Event Name"
      />
      {/*eslint-disable-next-line*/}
      <a
        className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full mt-2"
        onClick={() => {
          if (attendName.length === 0) return;
          if (window.confirm(`Create ${attendName}?`)) {
            post({ name: attendName });
            setAttendName('');
          }
        }}
      >
        Create Event
      </a>
    </div>
  );
}

function AdminAttendPrimaryEditor({ attend }) {
  const { put: putAttend } = useFetch(`/api/attends/${attend.id}`, []);
  const [name, setName] = useState(attend.name);
  const [desc, setDesc] = useState(attend.desc);
  const editedAttend = { name, desc };
  const edited = JSON.stringify(editedAttend) !== JSON.stringify({ name: attend.name, desc: attend.desc });
  return (
    <div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <input
          className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />
      </div>
      {edited && (
        <div className="border-t border-gray-200 pt-5 mt-6">
          {/*eslint-disable-next-line*/}
          <a
            className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full"
            onClick={() => {
              putAttend(editedAttend);
            }}
          >
            Save Text
          </a>
        </div>
      )}
    </div>
  );
}

export default AdminAttends;

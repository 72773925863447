import React from 'react';

function PartnershipsDetails() {
  return (
    <section>
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <h3 className="h3 mb-3">Events</h3>
            <p className="text-lg text-gray-600 mb-8">
              Throughout the year, we organize events geared towards providing our members the opportunity to interact
              with recruiters from our sponsor companies and expand their knowledge of emerging technologies and
              entrepreneurship. Currently, our events include recruiting events with our sponsor companies,{' '}
              {/* eslint-disable-next-line */}
              <a
                style={{
                  borderBottom: '1px dotted',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer'
                }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Science fair setting where selected students present their side projects to companies."
              >
                Side Project Expo
              </a>
              , {/* eslint-disable-next-line */}
              <a
                style={{
                  borderBottom: '1px dotted',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer'
                }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="A twelve hour competition hosted by Texas Convergent where students test and create a meaningful project with real world data."
              >
                Data Hack
              </a>
              , {/* eslint-disable-next-line */}
              <a
                data-toggle="tooltip"
                data-placement="bottom"
                title="An idea pitch competition co-hosted with USIT for student startups with prizes and potential funding opportunities."
                style={{
                  borderBottom: '1px dotted',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer'
                }}
              >
                Texas Shark Tank
              </a>
              , and {/* eslint-disable-next-line */}
              <a
                style={{
                  borderBottom: '1px dotted',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer'
                }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Convergent Build Teams present their semester long projects."
              >
                Demo Day
              </a>
              .
            </p>
          </div>
          <div className="max-w-3xl mx-auto">
            <h3 className="h3 mb-3">Build Teams</h3>
            <p className="text-lg text-gray-600 mb-8">
              The build teams are multidisciplinary groups of students from CS, Engineering, and Business that are
              tasked with utilizing emerging technologies and ideas to develop viable market solutions for a designated
              problem. Teams can focus on a problem or technology of a company’s choosing and develop a solution. Ideas
              and projects will be presented at the end of the semester, helping companies explore new options and
              inspire innovation amongst UT students.
            </p>
          </div>
          <div className="max-w-3xl mx-auto">
            <h3 className="h3 mb-3">Recruiting</h3>
            <p className="text-lg text-gray-600 mb-8">
              Here at Texas Convergent, we are providing our members an opportunity to experience interdisciplinary
              education from a new avenue. From technical, business, design, communication, and interpersonal skills,
              our club helps develop well-rounded individuals who are ready for today’s rapidly-changing, innovative
              workplaces. We seek to connect these amazing individuals to equally amazing opportunities through a
              variety of recruiting events throughout the year.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnershipsDetails;

import React from 'react';
import SlideShow from '../partials/SlideShow';
import { AWS_URL } from './../backend';

const SLIDESHOW = [
  { url: AWS_URL + '/slideshow/demo_day_1.jpg', caption: 'todo' },
  { url: AWS_URL + '/slideshow/demo_day_2.jpg', caption: 'todo' },
  { url: AWS_URL + '/slideshow/demo_day_3.jpg', caption: 'todo' },
  { url: AWS_URL + '/slideshow/hack_day_3.JPG', caption: 'todo' },
  { url: AWS_URL + '/slideshow/kickoff_1.jpg', caption: 'todo' },
  { url: AWS_URL + '/slideshow/officers2.jpg', caption: 'todo' }
].sort((a, b) => Math.random() - 0.5);

function HeroHome() {
  return (
    <section className="relative">
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1"
        aria-hidden="true"
      >
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="text-center pb-12 md:pb-16">
            <h1
              className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
              data-aos="zoom-y-out"
            >
              Texas{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-blue-200">
                Convergent
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                Enabling innovation at the intersection of technology, business, and design.
              </p>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <div>
                  <a
                    className="btn text-white bg-blue-500 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0"
                    href="/join"
                  >
                    Join Convergent
                  </a>
                </div>
                <div>
                  <a
                    className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4"
                    href="/buildteams"
                  >
                    Learn About Build Teams
                  </a>
                </div>
                <div>
                  <a
                    className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4 mt-4 md:mt-0 sm:mt-0"
                    href="/forge"
                  >
                    Learn About Forge
                  </a>
                </div>
                {/* <div>
                  <a
                    className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4 mt-4 md:mt-0 sm:mt-0"
                    href="/incubator"
                  >
                    Learn About Incubator
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <SlideShow images={SLIDESHOW} />
        </div>
      </div>
    </section>
  );
}

export default HeroHome;

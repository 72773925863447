import React from 'react';
import { AWS_URL } from './../backend';

function CompanyLogos() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4">Convergent provides the foundation for your future career</h1>
            <p className="text-xl text-gray-600">
              Build the skills necessary to be competitive in tech. Here's where a few of our past members have gone.
            </p>
          </div>
          <div className="flex flex-col items-center pt-6 md:pt-8">
            <div className="relative">
              <img src={AWS_URL + '/images/company-logos.png'} alt="logos" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CompanyLogos;

import React, { useState, useEffect } from 'react';
import Header from '../partials/Header';
import PeopleGrid from '../partials/PeopleGrid';
import Footer from '../partials/Footer';
import useFetch from 'use-http';

const getUserRank = (user) => {
  if (user.title.includes('President')) {
    return 5;
  } else if (user.title.includes('Director')) {
    return 4;
  } else if (user.title.includes('Lead')) {
    return 3;
  } else if (user.title.includes('Chair')) {
    return 2;
  }
  return 1;
};

function Officers({ flags }) {
  const [teams, setTeams] = useState(null);
  const { get } = useFetch(
    `/api/teams-formatted?boardId=${flags?.site_team_boardId}&incubatorId=${flags?.site_team_incubatorId}&btIds=${flags?.site_team_btIds}`,
    []
  );
  useEffect(() => {
    get().then((teams) => {
      setTeams(teams);
    });
  }, [flags, get]);
  let boardPeople = [];
  let btPeople = [];
  let incPeople = [];
  if (teams && teams.board) {
    boardPeople = teams.board.map((user) => {
      return {
        name: user.fullname,
        title: user.title,
        id: user.id
      };
    });
    boardPeople.sort((a, b) => getUserRank(b) - getUserRank(a));
    for (let bt of teams.buildTeams) {
      for (let lead of bt.leads) {
        btPeople.push({
          name: lead.fullname,
          title: bt.name,
          id: lead.id
        });
      }
    }
    incPeople = teams.incubLeads.map((user) => {
      return {
        name: user.fullname,
        title: user.orgTitle,
        id: user.id
      };
    });
  }
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        {teams && <PeopleGrid people={boardPeople} title={'Exec & Board'} subtitle={'Officers who run Convergent'} />}
        {teams && (
          <PeopleGrid
            smallTitle={true}
            people={btPeople}
            title={'Build Team Officers'}
            subtitle={'Officers who lead our build teams'}
          />
        )}
        {teams && (
          <PeopleGrid
            smallTitle={true}
            people={incPeople}
            title={'Forge Officers'}
            subtitle={'Officers who lead our Forge programs'}
          />
        )}
      </main>
      <Footer />
    </div>
  );
}

export default Officers;

import React, { useState } from 'react';
import { AWS_URL } from './../backend';

function PeopleGrid({ title, subtitle, people, smallTitle }) {
  return (
    <section className="bg-gradient-to-b from-gray-100 to-white border-t border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center pb-12 pt-10">
            {smallTitle ? (
              <h3 className="h3 mb-4" data-aos="zoom-y-out">
                {title}
              </h3>
            ) : (
              <h1 className="h1 mb-4" data-aos="zoom-y-out">
                {title}
              </h1>
            )}
            <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
              {subtitle}
            </p>
          </div>
          <div className="max-w-sm sm:max-w-5xl mx-auto sm:flex sm:flex-wrap sm:justify-center" data-aos-id-team>
            {people.map((person) => (
              <PersonItem person={person} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function PersonItem({ person }) {
  const normalPicSrc = `${AWS_URL}/profiles/${person.id}-normal.png`;
  const [profileUrl, setProfileUrl] = useState(normalPicSrc);
  return (
    <div
      key={person.name}
      className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
      data-aos="zoom-y-out"
      data-aos-anchor="[data-aos-id-team]"
    >
      <div className="flex flex-col items-center">
        <img
          className="relative rounded-full"
          src={profileUrl}
          width="120"
          height="120"
          alt={person.name}
          // random empty profile picture
          onError={() => setProfileUrl('https://tx-cvgt-public.s3.us-west-2.amazonaws.com/profiles/416-normal.png')}
        />
        <h4 className="text-xl font-bold mb-1">{person.name}</h4>
        <div className="text-blue-600 font-medium mb-2">{person.title}</div>
        {person.desc && <p className="text-gray-600 text-center mb-3">{person.desc}</p>}
      </div>
    </div>
  );
}

export default PeopleGrid;

import React from 'react';
import Header from '../partials/Header';
import PeopleGrid from '../partials/PeopleGrid';
import Footer from '../partials/Footer';
import useFetch from 'use-http';

function Alumni() {
  const { data: alumni = null } = useFetch('/api/alumni', []);
  const alumniPeople = (alumni || []).map((alum) => {
    return {
      name: alum.fullname,
      title: alum.fullTimePosition,
      id: alum.id
    };
  });
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        {alumni && (
          <PeopleGrid
            people={alumniPeople}
            title={'Our Alumni'}
            subtitle={'A few of our past Convergent members and officers'}
          />
        )}
      </main>
      <Footer />
    </div>
  );
}

export default Alumni;

import React from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import OrgFeatures from '../partials/OrgFeatures';
import LearnSkills from '../partials/LearnSkills';
import CompanyLogos from '../partials/CompanyLogos';
import ProjectsPreview from '../partials/ProjectsPreview';
import JoinCTA from '../partials/JoinCTA';
import Footer from '../partials/Footer';
import useFetch from 'use-http';
import InstaFeed from '../partials/InstaFeed';
import {
  faMobileAlt,
  faSolarPanel,
  faBrain,
  faFlask,
  faLightbulb,
  faLaptopCode,
  faBuilding,
  faUserFriends,
  faSearchDollar
} from '@fortawesome/free-solid-svg-icons';
import { AWS_URL } from './../backend';

const SKILLS = [
  { name: 'Building Web & Mobile Apps', desc: '', icon: faMobileAlt },
  { name: 'Doing Market Research', desc: '', icon: faSearchDollar },
  { name: 'Pitching Products', desc: '', icon: faLightbulb },
  { name: 'Applying AI & ML', desc: '', icon: faBrain },
  { name: 'Iterative Product Design', desc: '', icon: faSolarPanel },
  { name: 'Data Modeling & Analysis', desc: '', icon: faFlask }
];

const ORG_FEATURES = [
  {
    title: 'Build impactful tech products',
    desc: 'Join a diverse group of students and visionaries around campus to build fresh solutions to relevant tech challenges in Build Teams, or work directly to improve a budding startup or nonprofit in Forge.',
    icon: faLaptopCode,
    image: AWS_URL + '/images/build-impact.png'
  },
  {
    title: 'Gain industry-level skills',
    desc: 'Regardless of if you join Build Teams or Forge, you’ll learn and apply technical and product-thinking skills and strategies used in top tech companies and startups.',
    icon: faBuilding,
    image: AWS_URL + '/images/build-skills.png'
  },
  {
    title: 'Meet your people',
    desc: 'Make lifelong friends from multidisciplinary backgrounds, and tap into Convergent’s expansive alumni network - nationwide, and across all industries.',
    icon: faUserFriends,
    image: AWS_URL + '/images/meet-people.png'
  }
];

const STEPS = [
  {
    title: 'Build Teams',
    subtitle:
      'Build Teams are a guided, immersive experience through building a tech product that solves a wicked tech problem.',
    steps: [
      { title: 'Apply', desc: 'Apply to Build Teams through our website, no experience required!' },
      {
        title: 'Brainstorm',
        desc: 'Choose a case you’re passionate about, and brainstorm problem areas with your diverse team'
      },
      {
        title: 'Build',
        desc: 'Work together with your team and Build Team leads to develop the skills needed to build out your big idea'
      },
      {
        title: 'Demo',
        desc: 'Showcase your hard work in our semesterly Demo Day, where you’ll enter into a friendly competition with other Build Team projects'
      }
    ]
  },
  {
    title: 'Forge',
    subtitle:
      'Forge is our in-house accelerator for startups and nonprofits - where clients can be matched with product managers and engineers to help level up their idea.',
    steps: [
      {
        title: 'Apply',
        desc: 'Complete our company application if you are a startup or nonprofit, or complete our Engineer/Designer application if you want to work within a startup'
      },
      {
        title: 'Build',
        desc: 'Meet your pod (company founder(s), technical product manager, engineers, designers) and plan out goals and deliverables for the semester'
      },
      {
        title: 'Network',
        desc: 'Take advantage of Convergent’s network of resources to gain access to new skills and new opportunities for funding and growth'
      },
      {
        title: 'Demo',
        desc: 'Show off your work and progress at our end-of-semester Demo Day pitch'
      }
    ]
  }
];

function Home() {
  const { data: projects = null } = useFetch(`/api/projects-all`, []);
  let btProjects = [];
  let incProjects = [];
  let npProjects = [];
  let startProjects = [];
  if (projects) {
    btProjects = projects.filter(
      (project) => project.team !== 'Incubator' && project.team !== 'Nonprofits' && project.team !== 'Startups'
    );
    incProjects = projects.filter((project) => project.team === 'Incubator');
    npProjects = projects.filter((project) => project.team === 'Nonprofits');
    startProjects = projects.filter((project) => project.team === 'Startups');
    btProjects.sort((a, b) => Math.random() - 0.5);
    incProjects.sort((a, b) => Math.random() - 0.5);
    npProjects.sort((a, b) => Math.random() - 0.5);
    startProjects.sort((a, b) => Math.random() - 0.5);
    btProjects = btProjects.slice(0, 3);
    incProjects = incProjects.slice(0, 3);
    npProjects = npProjects.slice(0, 3);
    startProjects = startProjects.slice(0, 3);
  }
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <HeroHome />
        <OrgFeatures steps={STEPS} features={ORG_FEATURES} />
        <LearnSkills skills={SKILLS} />
        <CompanyLogos />
        <ProjectsPreview
          title={"See what Convergent's already built."}
          projects={[
            {
              title: 'Build Team Projects',
              overrideLink: '/pastprojects',
              projects: btProjects
            },
            {
              title: 'Incubator Companies',
              overrideLink: '/portfolio',
              projects: incProjects
            },
            {
              title: 'Forge Nonprofits',
              overrideLink: '/forge-portfolio',
              projects: npProjects
            },
            {
              title: 'Forge Startups Companies',
              overrideLink: '/forge-portfolio',
              projects: startProjects
            }
          ]}
        />
        <InstaFeed />
        <JoinCTA />
      </main>
      <Footer />
    </div>
  );
}

export default Home;

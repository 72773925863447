import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import ShopList from './../partials/ShopList';

function Shop() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <ShopList />
      </main>
      <Footer />
    </div>
  );
}

export default Shop;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import useFetch from 'use-http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faFacebook, faInstagram, faMedium } from '@fortawesome/free-brands-svg-icons';

const SOCIALS = [
  { name: 'Email', link: 'mailto:membership@txconvergent.org', icon: faEnvelope },
  { name: 'Github', link: 'https://github.com/txconvergent/', icon: faGithub },
  { name: 'Facebook', link: 'https://www.facebook.com/txconvergent/', icon: faFacebook },
  { name: 'Instagram', link: 'https://www.instagram.com/txconvergent/', icon: faInstagram },
  { name: 'Medium', link: 'https://medium.com/txconvergent', icon: faMedium }
];

function SubscribeFooter() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const { post } = useFetch('/api/newsletter');
  const submitForm = () => {
    post({ name, email });
    setShowSuccess(true);
  };
  return (
    <div className="sm:col-span-12 md:col-span-8 lg:col-span-8">
      <h6 className="text-gray-800 font-medium mb-2">Subscribe</h6>
      <p className="text-sm text-gray-600 mb-4">Get the latest Convergent news and opportunites.</p>
      <form>
        <div className="flex flex-wrap mb-4">
          <div className="w-full">
            <label className="block text-sm sr-only" htmlFor="sub-name">
              Name
            </label>
            <div className="relative flex items-center max-w-xs">
              <input
                id="sub-name"
                type="name"
                className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
                placeholder="Your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <label className="block text-sm sr-only" htmlFor="sub-email">
              Email
            </label>
            <div className="relative flex items-center max-w-xs mt-2">
              <input
                id="sub-email"
                type="email"
                className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button
                className="absolute inset-0 left-auto"
                aria-label="Subscribe"
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
              >
                <span className="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>
                <svg
                  className="w-3 h-3 fill-current text-blue-600 mx-3 flex-shrink-0"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                    fillRule="nonzero"
                  />
                </svg>
              </button>
            </div>
            {showSuccess && <p className="mt-2 text-green-600 text-sm">Thanks for subscribing!</p>}
          </div>
        </div>
      </form>
    </div>
  );
}

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">
          <div className="sm:col-span-12 lg:col-span-3">
            <div className="mb-2">
              <Link to="/" className="inline-block" aria-label="Convergent">
                <img width={'50px'} src={Logo} alt="logo" />
              </Link>
            </div>
          </div>
          <SubscribeFooter />
        </div>

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
          {/* Social links */}
          <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            {SOCIALS.map((social) => (
              <li className="ml-4">
                <a
                  href={social.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label={social.name}
                >
                  <FontAwesomeIcon
                    icon={social.icon}
                    style={{
                      color: '#555',
                      backgroundColor: '#fff',
                      width: '2em',
                      height: '2em',
                      borderRadius: '1em',
                      padding: '0.4em'
                    }}
                  />
                </a>
              </li>
            ))}
          </ul>
          <div className="text-sm text-gray-600 mr-4">&copy; 2023 Texas Convergent. All rights reserved.</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import Accordion from '../utils/Accordion';

function Faqs({ title, style, faq }) {
  return (
    <section style={style}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">
          <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2">{title}</h2>
          </div>
          {faq.map((section, i) => (
            <div id={section.title} key={section.title} style={{ marginTop: i === 0 ? '0' : '3em' }}>
              <div className="max-w-3xl mx-auto text-center pb-6 md:pb-8">
                <h3 className="h3">{section.title}</h3>
              </div>
              <ul className="max-w-3xl mx-auto pl-12">
                {section.questions.map((q) => (
                  <Accordion key={q.question} title={q.question}>
                    {q.answer}
                  </Accordion>
                ))}
                <span className="block border-t border-gray-200" aria-hidden="true"></span>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Faqs;

import React, { useEffect } from 'react';
import './css/style.scss';

import { Switch, Route, useLocation } from 'react-router-dom';
import { Provider } from 'use-http';
import { API_URL } from './backend';
import AOS from 'aos';
import Sticky from 'sticky-js';
import { focusHandling } from 'cruip-js-toolkit';
import useFetch from 'use-http';
import Home from './pages/Home';
import FAQ from './pages/FAQ';
import PageNotFound from './pages/PageNotFound';
import Partnerships from './pages/Partnerships';
import Incubator from './pages/Incubator';
import Forge from './pages/Forge';
import ContactUs from './pages/ContactUs';
import ToDo from './pages/ToDo';
import Join from './pages/Join';
import BuildTeams from './pages/BuildTeams';
import PastProjects from './pages/PastProjects';
import Portfolio from './pages/Portfolio';
import ForgePortfolio from './pages/ForgePortfolio';
import Alumni from './pages/Alumni';
import AdminDashboard from './admin/AdminDashboard';
import Officers from './pages/Officers';
import Shop from './pages/Shop';

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic'
    });
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky('[data-sticky]');
  });

  const { data: flags = null } = useFetch(API_URL + `/api/flags`, []);

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Provider url={API_URL}>
        <Switch>
          <Route exact path="/">
            <Home flags={flags} />
          </Route>
          <Route exact path="/faq">
            <FAQ flags={flags} />
          </Route>
          <Route exact path="/todo">
            <ToDo />
          </Route>
          <Route exact path="/shop">
            <Shop flags={flags} />
          </Route>
          <Route exact path="/team">
            <Officers flags={flags} />
          </Route>
          <Route exact path="/partnerships">
            <Partnerships flags={flags} />
          </Route>
          <Route exact path="/incubator">
            <Incubator flags={flags} />
          </Route>
          <Route exact path="/forge">
            <Forge flags={flags} />
          </Route>
          <Route exact path="/contact">
            <ContactUs flags={flags} />
          </Route>
          <Route exact path="/buildteams">
            <BuildTeams flags={flags} />
          </Route>
          <Route exact path="/pastprojects">
            <PastProjects flags={flags} />
          </Route>
          <Route exact path="/portfolio">
            <Portfolio flags={flags} />
          </Route>
          <Route exact path="/forge-portfolio">
            <ForgePortfolio flags={flags} />
          </Route>
          <Route exact path="/join">
            <Join flags={flags} />
          </Route>
          <Route exact path="/alumni">
            <Alumni flags={flags} />
          </Route>
          <Route exact path="/admin-dashboard">
            <AdminDashboard flags={flags} />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Provider>
    </>
  );
}

export default App;

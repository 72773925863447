import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { titleCase } from './../utils';
import { AWS_URL } from './../backend';

function ProjectsPreview({ projects, overrideLink, title }) {
  let [tab, setTab] = useState(0);
  let items = projects[tab]?.projects || [];
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-12">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-12">
            <h2 className="h2">{title}</h2>
          </div>
          <div className="mb-12 md:mb-16">
            <ul className="flex flex-wrap justify-center text-sm font-medium -m-2">
              {projects.map((projectGroup, i) => (
                <li className="m-2">
                  <button
                    className={
                      tab === i
                        ? 'inline-flex text-center text-gray-100 py-2 px-4 rounded-full bg-blue-500 hover:bg-blue-700 transition duration-150 ease-in-out'
                        : 'inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out'
                    }
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setTab(i);
                    }}
                  >
                    {projectGroup.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="max-w-sm mx-auto md:max-w-none">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
              {items.map((project) => (
                <article className="flex flex-col h-full" data-aos="zoom-y-out">
                  <header>
                    <Link to={project.link} className="block mb-6">
                      <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                        <img
                          className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out"
                          src={`${AWS_URL}/projects/${project.id}.png`}
                          width={250}
                          height={352}
                          alt={project.name}
                        />
                      </figure>
                    </Link>
                    <h3 className="text-xl font-bold leading-snug tracking-tight">
                      <div className="mb-3">
                        <ul className="flex flex-wrap text-xs font-medium -m-1">
                          <li className="m-1">
                            <button className="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out">
                              {titleCase(project.season)} {project.year}
                            </button>
                          </li>
                          {project.team !== 'Incubator' && (
                            <li className="m-1">
                              <button className="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out">
                                {project.team}
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                      <a href={projects[tab].overrideLink || project.slidesURL} className="hover:underline">
                        {project.name}
                      </a>
                    </h3>
                  </header>
                  <footer className="text-sm flex items-center mt-4">
                    <div className="text-gray-600">{project.desc}</div>
                  </footer>
                </article>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectsPreview;

import React from 'react';

import NonProfitsModes from './NonProfitModes';
import ForgeModes from './ForgeModes';
import useFetch from 'use-http';
import ProjectsPreview from './ProjectsPreview';
import Steps from './Steps';

const MODES = [
  {
    title: 'Engineer',
    slogan: 'Forge is an opportunity for you to code & build products that create a large-scale impact!',
    desc: 'Forge Engineers are responsible for the technical production, delivery, and handoff of a client project throughout the semester. Engineers will use their technical background with various frameworks and languages to build out solutions devised by their Tech Lead. Engineers will follow the organization and deliverables set by Product Leads.',
    cta: 'Join us and use your developmental skills to build software for Austin-area startups',
    appLink: 'http://tinyurl.com/f24convergent'
  },
  {
    title: 'Product Analyst',
    slogan: 'Forge is an opportunity for you to refine products to create a large-scale impact!',
    desc: 'Product analysts are responsible for integrating product feedback into actionable items. Product analysts will work with Product Leads to improve project success by researching market and user trends, analyzing project limitations, mitigating risk, and providing a seamless go-to-market strategy. Product analysts will work together with tech and design teams to convert research and feedback into tangible solutions.',
    cta: 'Join us and build projects for Austin-area startups',
    appLink: 'http://tinyurl.com/f24convergent'
  },
  {
    title: 'Designer',
    slogan: 'Forge is an opportunity for you to design products to create a large-scale impact!',
    desc: 'Designers are responsible for creating designs to implement throughout the semester. Designers will iterate on designs based on user and client feedback. Designers will take part in exploratory research, wireframing, prototyping, ideation, and user testing of the client project throughout the semester. Designers will work with the engineering team to monitor the implementation of created designs and to provide UI feedback. Designers may also create branding and marketing materials and work with the product team on marketing strategies.',
    cta: 'Join us and design creative experiences to help startups provide engaging user experiences',
    appLink: 'http://tinyurl.com/f24convergent'
  }
];

const STEPS = [
  {
    title: 'Beginning of Each Semester',
    bullets: ['Discuss the semester’s software project with exploratory meetings with company/non-profit']
  },
  {
    title: 'Throughout the Semester',
    bullets: [
      'Set feasible deliverables for Product Manager’s, Technical Project Manager’s, Engineers and Designers to deliver the project’s main goal',
      'Work towards these deliverables'
    ]
  },
  {
    title: 'End of Each Semester',
    bullets: [
      'Present progress from the semester',
      'Achieve the set deliverables',
      'Set possible next steps for the company/non-profit'
    ]
  }
];

function ForgeAll({ forgeTeams }) {
  const { data: projects = null } = useFetch(`/api/projects-all`, []);
  let btProjectsByYear = {};
  let projectGroups = [];
  if (projects) {
    //Temporary. There should be only one team from now on
    let btProjects = projects.filter((project) => (project.team === 'Nonprofits' || project.team === 'Startups'));
    for (let proj of btProjects) {
      if (!proj) {
        continue;
      }
      if (!(proj.year in btProjectsByYear)) {
        btProjectsByYear[proj.year] = [];
      }
      btProjectsByYear[proj.year].push(proj);
    }
    for (let year in btProjectsByYear) {
      projectGroups.push({
        title: '' + year,
        projects: btProjectsByYear[year]
      });
    }
  }
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <main className="flex-grow">
        <ForgeModes modes={MODES} forgeTeams={[forgeTeams[0]]} />
        <Steps
          title={'Program Overview'}
          subtitle={'Have questions? Reach us at memberships@txconvergent.org'}
          steps={STEPS}
        />
        {projects && <ProjectsPreview title={'Past Projects'} overrideLink={'/portfolio'} projects={projectGroups} />}
        <hr></hr>
      </main>
    </div>
  );
}

export default ForgeAll;

import React from 'react';
import ProjectsGallery from './ProjectsGallery';
import useFetch from 'use-http';

function NonprofitsPortfolio() {
  const { data: projects = null } = useFetch(`/api/projects-all`, []);
  let btProjectsByYear = {};
  let projectGroups = [];
  if (projects) {
    let btProjects = projects.filter((project) => project.team === 'Nonprofits');
    for (let proj of btProjects) {
      if (!proj) {
        continue;
      }
      if (!(proj.year in btProjectsByYear)) {
        btProjectsByYear[proj.year] = [];
      }
      btProjectsByYear[proj.year].push(proj);
    }
    for (let year in btProjectsByYear) {
      projectGroups.push({
        title: '' + year,
        projects: btProjectsByYear[year]
      });
    }
  }
  return (
    <div className="flex flex-col min-h-screen overflow-hidden" id="nonprofitsPortfolio">
      <main className="flex-grow">
        <ProjectsGallery title={'Forge Nonprofits'} projectGroups={projectGroups} />
      </main>
    </div>
  );
}

export default NonprofitsPortfolio;

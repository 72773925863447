import React, { useState } from 'react';
import DetailedCTA from '../partials/DetailedCTA';

function IncubatorModes({ modes }) {
  let [tab, setTab] = useState(0);
  let mode = modes[tab];
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6" id="Incubator">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1">Incubator</h1>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
              Making startups ideas a reality
            </p>
          </div>

          <div className="border-b border-gray-300 pb-4 mb-12">
            <ul className="flex flex-wrap justify-center md:justify-start font-medium -mx-5 -my-1">
              <li className="mx-5 my-1">
                <a className="text-black-600" href="#0">
                  I am a...
                </a>
              </li>
              {modes.map((mode, i) => (
                <li className="mx-5 my-1">
                  {/* eslint-disable-next-line */}
                  <a
                    className={i === tab ? 'text-blue-600 hover:underline' : 'text-gray-600 hover:underline'}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setTab(i);
                    }}
                  >
                    {mode.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="max-w-8xl mx-auto px-4 sm:px-6">
            <div className="pb-12 md:pb-20">
              <div className="max-w-12xl mx-auto">
                <h3 className="h3 mb-3">For {mode.title}s</h3>
                <p className="text-lg text-gray-600 mb-8">{mode.slogan}</p>
                <p className="text-lg text-gray-600 mb-8">{mode.desc}</p>
              </div>
              <DetailedCTA
                title={`Want to be a ${mode.title.toLowerCase()} at Convergent?`}
                subtitle={mode.cta}
                href={mode.appLink}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IncubatorModes;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import useFetch from 'use-http';
import AddressForm from './../utils/AddressForm';

const CART_LS_KEY = 'convergent:cart';
const ADDR_LS_KEY = 'convergent:addr';

const DEFAULT_ADDR = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  state_code: '',
  state_name: '',
  country_code: 'US',
  country_name: 'United States',
  zip: '',
  email: ''
};

function ShopList() {
  const { data: products = null } = useFetch(`/api/shop/products`, []);
  const [cart, setCartState] = useState([]);
  const [addr, setAddrState] = useState(DEFAULT_ADDR);
  const [tab, setTab] = useState(0);
  let cartValue = 0;
  for (let item of cart) {
    cartValue += parseFloat(item.price);
  }
  let setCart = (cart) => {
    setCartState(cart);
    window.localStorage.setItem(CART_LS_KEY, JSON.stringify(cart));
  };
  let setAddr = (addr) => {
    setAddrState(addr);
    window.localStorage.setItem(ADDR_LS_KEY, JSON.stringify(addr));
  };
  useEffect(() => {
    const savedCart = window.localStorage.getItem(CART_LS_KEY);
    if (savedCart) {
      setCartState(JSON.parse(savedCart));
    }
    const savedAddress = window.localStorage.getItem(ADDR_LS_KEY);
    if (savedAddress) {
      setAddrState(JSON.parse(savedAddress));
    }
  }, []);
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1">Convergent Merch Shop</h1>
          </div>
          <div className="border-b border-gray-300 pb-4 mb-12">
            <ul className="flex flex-wrap justify-center md:justify-start font-medium -mx-5 -my-1">
              <li className="mx-5 my-1">
                <button className={tab === 0 ? 'text-blue-600' : 'text-gray-800'} onClick={() => setTab(0)}>
                  Merch
                </button>
              </li>
              <li className="mx-5 my-1">
                <button className={tab === 1 ? 'text-blue-600' : 'text-gray-800'} onClick={() => setTab(1)}>
                  Checkout ({cart.length} items, ${cartValue})
                </button>
              </li>
            </ul>
          </div>
          {tab === 0 && <MerchTab products={products} setCart={setCart} cart={cart} />}
          {tab === 1 && <CheckoutTab cart={cart} setCart={setCart} addr={addr} setAddr={setAddr} />}
        </div>
      </div>
    </section>
  );
}

function MerchTab({ products, setCart, cart }) {
  return (
    <div className="max-w-sm mx-auto md:max-w-none">
      <div className="grid gap-12 md:grid-cols-2 md:gap-x-6 md:gap-y-8 items-start">
        {products &&
          products.map((product) => <ProductDisplay product={product} addToCart={(v) => setCart(cart.concat([v]))} />)}
      </div>
    </div>
  );
}

function ProductDisplay({ product, addToCart }) {
  const [variant, setVariant] = useState(product.variants[0]);
  return (
    <article className="flex flex-col h-full" data-aos="zoom-y-out">
      <header>
        <Link to="/shop" className="block mb-6">
          <figure
            className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded md:mt-3 md:mb-3"
            style={{ height: '500px' }}
          >
            <img
              className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out"
              src={variant.imgURL}
              width="350"
              height="350"
              alt={variant.name}
            />
          </figure>
        </Link>
        <div className="text-center">
          <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
            <Link to="/shop" className="hover:underline">
              {product.name} - ${variant.price}
            </Link>
          </h3>
          <div className="flex justify-center max-w-xs m-auto mb-3" data-aos="zoom-y-out">
            <VariantSelect
              options={product.variants.map((v) => {
                return { name: v.name.includes(' - ') ? v.name.split(' - ', 2)[1] : v.name, value: v };
              })}
              onChange={(newVariant) => setVariant(newVariant)}
            />
          </div>
          <button
            className="inline-flex font-small text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out"
            onClick={() => addToCart(variant)}
          >
            + Add
          </button>
        </div>
      </header>
    </article>
  );
}

function VariantSelect({ options, onChange }) {
  return (
    <Select
      options={options.map((opt) => {
        return { value: opt.value, label: opt.name };
      })}
      onChange={(e) => onChange(e.value)}
      defaultValue={{ value: options[0].value, label: options[0].name }}
    />
  );
}

function CheckoutTab({ cart, setCart, addr, setAddr }) {
  const { post: getEstimate } = useFetch('/api/shop/estimate', []);
  const { post: createOrder } = useFetch('/api/shop/order', []);
  const [estimate, setEstimate] = useState({});
  const [confirmCheck, setConfirmCheck] = useState(false);
  const addrComplete =
    addr.name !== '' && addr.address1 !== '' && addr.zip !== '' && addr.city !== '' && addr.email !== '';
  useEffect(() => {
    if (cart.length === 0 || !addrComplete) {
      setEstimate({});
      return;
    }
    (async () => {
      const est = await getEstimate({
        items: cart,
        recipient: addr
      });
      setEstimate(est);
      setConfirmCheck(false);
    })();
  }, [getEstimate, cart, addr, addrComplete]);
  const costs = estimate?.estimate?.costs;
  const readyToOrder = confirmCheck && costs && addrComplete;
  return (
    <section>
      <div className="max-w-6xl mx-auto px-1 sm:px-6">
        <div className="pt-1 md:pt-1">
          <div className="overflow-x-auto">
            <table className="table-auto w-full border-b border-gray-200">
              <thead>
                <tr className="text-base sm:text-lg border-t border-gray-200">
                  <th className="text-bold text-left pr-2 py-4 min-w-48">Item</th>
                  <th className="text-bold text-center px-2 py-4">Price</th>
                  <th className="text-bold text-center px-2 py-4"></th>
                </tr>
              </thead>
              <tbody>
                {cart.map((item) => (
                  <tr className="border-t border-gray-200">
                    <td className="text-sm sm:text-base pr-2 py-4">
                      <div className="font-medium underline">{item.product_name}</div>
                      <div className="text-gray-600">{item.name}</div>
                    </td>
                    <td className="text-sm px-2 py-4 text-center font-medium">${item.price}</td>
                    <td className="text-sm px-2 py-4 text-center font-medium">
                      <button
                        onClick={() => {
                          let newCart = [];
                          let foundItem = false;
                          for (let cartItem of cart) {
                            if (cartItem.external_id === item.external_id && !foundItem) {
                              foundItem = true;
                              continue;
                            }
                            newCart.push(item);
                          }
                          setCart(newCart);
                        }}
                      >
                        ❌
                      </button>
                    </td>
                  </tr>
                ))}
                {!addrComplete && (
                  <tr className="border-t border-gray-200">Fill in your address to see a pricing estimate.</tr>
                )}
                {estimate.error && <tr className="border-t border-gray-200">Error: {estimate.error}</tr>}
                {costs && (
                  <tr className="border-t border-gray-200">
                    <td className="text-sm sm:text-base pr-2 py-2">Fees</td>
                    <td className="text-sm px-2 py-2 text-center font-medium">
                      ${costs.additional_fee + costs.fulfillment_fee + costs.digitization}
                    </td>
                  </tr>
                )}
                {costs && (
                  <tr className="border-t border-gray-200">
                    <td className="text-sm sm:text-base pr-2 py-2">Tax</td>
                    <td className="text-sm px-2 py-2 text-center font-medium">${costs.tax + costs.vat}</td>
                  </tr>
                )}
                {costs && (
                  <tr className="border-t border-gray-200">
                    <td className="text-sm sm:text-base pr-2 py-2">Shipping</td>
                    <td className="text-sm px-2 py-2 text-center font-medium">${costs.shipping}</td>
                  </tr>
                )}
                {costs && (
                  <tr className="border-t border-gray-200">
                    <td className="text-sm sm:text-base pr-2 py-2">
                      <b>Total</b>
                    </td>
                    <td className="text-sm px-2 py-2 text-center font-medium">${costs.total}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="pt-5">
          <AddressForm addr={addr} setAddr={setAddr} />
        </div>
        <div className="flex flex-wrap -mx-3 mt-6">
          <div className="max-w-sm mx-auto">
            <div className="flex flex-wrap -mx-3 mb-4">
              {costs && (
                <label className="flex items-center">
                  <input
                    checked={confirmCheck}
                    onChange={(e) => setConfirmCheck(e.target.checked)}
                    type="checkbox"
                    className="form-checkbox"
                  />
                  <span className="text-gray-600 ml-2">
                    I agree to Venmo <b>@TXConvergent</b> ${costs.total} and acknowledge that this order will not be
                    processed until this is done.
                  </span>
                </label>
              )}
            </div>
            <div className="w-full px-3">
              {!readyToOrder && (
                <button className="btn text-white bg-gray-600 hover:bg-gray-700 w-full">Place Order</button>
              )}
              {readyToOrder && (
                <button
                  className="btn text-white bg-blue-600 hover:bg-blue-700 w-full"
                  onClick={async () => {
                    const orderResp = await createOrder({ items: cart, recipient: addr });
                    if (orderResp.error) {
                      alert(orderResp.error);
                    } else {
                      alert('Order recieved! Your order will be processed once we recieve your Venmo.');
                      setCart([]);
                      setAddr(DEFAULT_ADDR);
                    }
                  }}
                >
                  Place Order
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ShopList;

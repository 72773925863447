import useFetch from 'use-http';
import { useState } from 'react';

function AdminSimpleFieldsEditor({ fields, restPath }) {
  const { put: putItem } = useFetch(restPath, []);
  const [showEdited, setShowEdited] = useState();
  const inputs = {};
  for (let field of fields) {
    inputs[field.field] = field.value;
  }
  const [value, setValue] = useState(inputs);
  return (
    <div>
      {fields.map((field) => (
        <div>
          {(!field.type || field.type === 'text') && (
            <input
              className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
              value={value[field.field]}
              onChange={(e) => {
                const newValue = Object.assign({}, value, { [field.field]: e.target.value });
                setValue(newValue);
                setShowEdited(JSON.stringify(newValue) !== JSON.stringify(inputs));
              }}
            />
          )}
          {field.type === 'checkbox' && (
            <div>
              <p>{field.field}: </p>
              <input
                type="checkbox"
                checked={value[field.field]}
                onClick={(e) => {
                  const newValue = Object.assign({}, value, { [field.field]: e.target.checked });
                  setValue(newValue);
                  setShowEdited(JSON.stringify(newValue) !== JSON.stringify(inputs));
                }}
              />
            </div>
          )}
        </div>
      ))}
      {showEdited && (
        <div className="border-t border-gray-200 pt-5 mt-6">
          {/*eslint-disable-next-line*/}
          <a
            className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full"
            onClick={() => {
              putItem(value);
              setShowEdited(false);
            }}
          >
            💾
          </a>
        </div>
      )}
    </div>
  );
}

export default AdminSimpleFieldsEditor;
